import { client } from "./axios"

const createAgencyLoungeConfig = (body: AgencyLoungeConfigCreateBody): Promise<AgencyLoungeConfig> =>
  client.post(`/agency_lounge_configs`, body)
const deleteAgencyLoungeConfig = ({ id }: ByID): Promise<null> => client.delete(`/agency_lounge_configs/${id}`)
const fetchAgencyLoungeConfigs = (params?: PaginateParams): Promise<PaginateResponse<AgencyLoungeConfig>> =>
  client.get(`/agency_lounge_configs`, { params })
const getAgencyLoungeConfig = ({ id }: ByID): Promise<AgencyLoungeConfig> => client.get(`/agency_lounge_configs/${id}`)
const updateAgencyLoungeConfig = ({ id, ...body }: AgencyLoungeConfigUpdateBody): Promise<AgencyLoungeConfig> =>
  client.patch(`/agency_lounge_configs/${id}`, body)

const agencyLoungeConfigService = {
  createAgencyLoungeConfig,
  deleteAgencyLoungeConfig,
  fetchAgencyLoungeConfigs,
  getAgencyLoungeConfig,
  updateAgencyLoungeConfig,
}

export default agencyLoungeConfigService
