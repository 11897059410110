import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Stack, TextField } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { queryClient, userService } from "services"
import { isValidEmail } from "utils/validator"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  user?: User
}

const UserUpdateDialog = ({ onClose, onSuccess, user, ...props }: Props) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<UserUpdateBody>({
    defaultValues: {
      email: user?.email ?? "",
      firstName: user?.firstName ?? "",
      id: user?.id ?? "",
      lastName: user?.lastName ?? "",
    },
  })

  const updateUserMutation = useMutation({ mutationFn: userService.updateUser })

  const onSubmit = async (values: UserUpdateBody) => {
    try {
      await updateUserMutation.mutateAsync(values)
      enqueueSnackbar("Cập nhật tài khoản thành công")

      queryClient.invalidateQueries({ queryKey: ["userService.getUser"] })
      queryClient.invalidateQueries({ queryKey: ["userService.fetchUsers"] })
      onClose()
      onSuccess?.()
    } catch (error) {
      console.error("Lỗi cập nhật tài khoản!", { error })
    }
  }

  useEffect(() => {
    if (props.open) {
      reset(user, { keepDefaultValues: true })
    }
  }, [reset, props.open, user])

  return (
    <Dialog maxWidth="xs" {...props}>
      <DialogCloseButton onClick={onClose} />

      <DialogTitle>{"Cập nhật tài khoản"}</DialogTitle>

      <DialogContent>
        <Stack gap={3}>
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState: { error } }) => (
              <TextField fullWidth label="Họ" required {...field} error={!!error} helperText={error?.message} />
            )}
            rules={{
              required: "Họ không được để trống",
            }}
          />
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState: { error } }) => (
              <TextField fullWidth label="Tên" required {...field} error={!!error} helperText={error?.message} />
            )}
            rules={{
              required: "Tên không được để trống",
            }}
          />
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <TextField fullWidth label="Email" required {...field} error={!!error} helperText={error?.message} />
            )}
            rules={{
              required: "Email không được để trống",
              validate: {
                isValid: (value) => isValidEmail(value) || "Email không hợp lệ",
              },
            }}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>

        <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
          {"Cập nhật"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default UserUpdateDialog
