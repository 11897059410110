import { Box } from "@mui/material"
import { P } from "models/Permissions"
import { AgencyList } from "views/Agency"
import { AgencyLoungeConfigCreate, AgencyLoungeConfigList } from "views/AgencyLoungeConfig"
import { AirportList } from "views/Airport"
import { AirportLoungeList } from "views/AirportLounge"
import { AirportLoungeBookingList, AirportLoungeBookingView } from "views/AirportLoungeBooking"
import { AirportLoungeBookingCreate } from "views/AirportLoungeBookingCreate"
import { AirportLoungeFareList } from "views/AirportLoungeFare"
import { AuditLogList } from "views/AuditLog"
import { ErrorPage } from "views/Error"
import { Profile } from "views/Profile"
import { SpecialServiceList } from "views/SpecialService"
import { SpecialServiceCreate } from "views/SpecialServiceCreate"
import { UserList } from "views/User"

const privateRoute = {
  airportLoungeBookings: {
    component: AirportLoungeBookingList,
    name: "Danh sách vé",
    path: "/airport_lounge_bookings",
    requiredPermission: P.AIRPORT_LOUNGE_BOOKING_FETCH,
  },
  airportLoungeBookingsView: {
    component: AirportLoungeBookingView,
    name: "Thông tin dịch vụ",
    path: "/airport_lounge_bookings/:id",
    requiredPermission: P.AIRPORT_LOUNGE_BOOKING_READ,
    url: ({ id }: ByID) => `/airport_lounge_bookings/${id}`,
  },

  airportLoungeFormBookingsCreate: {
    component: AirportLoungeBookingCreate,
    name: "Đặt vé phòng chờ",
    path: "/airport_lounge_form_bookings",
    requiredPermission: P.AIRPORT_LOUNGE_BOOKING_CREATE,
  },
  airportLoungeFormBookingsUpdate: {
    component: AirportLoungeBookingCreate,
    name: "Thông tin dịch vụ",
    path: "/airport_lounge_form_bookings/:id",
    requiredPermission: P.AIRPORT_LOUNGE_BOOKING_UPDATE,
    url: ({ id }: ByID) => `/airport_lounge_form_bookings/${id}`,
  },
  auditLogs: {
    component: AuditLogList,
    name: "Audit Logs",
    path: "/audit-logs",
  },
  errors: {
    component: ErrorPage,
    name: "Lỗi",
    path: "/errors/:code",
  },
  home: {
    component: SpecialServiceList,
    name: "Dịch vụ bảo hiểm",
    path: "/special_services",
  },
  profile: {
    component: Profile,
    name: "Profile",
    path: "/profile",
  },
  settings: {
    component: Box,
    name: "Cài đặt",
    path: "/settings",
  },
  settingsAgencies: {
    component: AgencyList,
    name: "Đại lý",
    path: "/settings/agencies",
  },
  settingsAgencyLoungeConfigs: {
    component: AgencyLoungeConfigList,
    name: "Cài đặt giá theo đại lý",
    path: "/settings/agency-lounge-configs",
  },
  settingsAgencyLoungeConfigsCreate: {
    component: AgencyLoungeConfigCreate,
    name: "Cài đặt giá theo đại lý",
    path: "/settings/agency-lounge-configs/create",
  },
  settingsAirportLoungeFare: {
    component: AirportLoungeFareList,
    name: "Loại vé",
    path: "/settings/lounge-fares",
  },
  settingsAirportLounges: {
    component: AirportLoungeList,
    name: "Phòng chờ sân bay",
    path: "/settings/lounges",
  },
  settingsAirports: {
    component: AirportList,
    name: "Sân bay",
    path: "/settings/airports",
  },
  settingsUsers: {
    component: UserList,
    name: "Tài khoản",
    path: "/settings/users",
  },
  specialServices: {
    component: SpecialServiceList,
    name: "Dịch vụ bảo hiểm",
    path: "/special_services",
    requiredPermission: P.SPECIAL_SERVICE_FETCH,
  },
  specialServicesDraft: {
    component: SpecialServiceCreate,
    name: "Mua bảo hiểm",
    path: "/insurance_services_draft",
    requiredPermission: P.SPECIAL_SERVICE_CREATE_DRAFT,
  },
}

export default privateRoute
