import { Stack } from "@mui/material"
import { AppHeader } from "containers"
import { useHomePath, useResponsive } from "hooks"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { createSearchParams, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { profileSelector } from "reducers/profileSlice"
import { authRoute, privateRoute } from "routes"

const PrivateLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const homePath = useHomePath()
  const { isDesktop } = useResponsive()
  const { isLoggedIn, permissions } = useSelector(profileSelector)

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(
        {
          pathname: authRoute.login.url,
          search: createSearchParams({ callbackUrl: location.pathname }).toString(),
        },
        { replace: true },
      )
    }
  }, [isLoggedIn, location.pathname, navigate])

  return (
    <main style={isDesktop ? { marginLeft: "320px" } : {}}>
      <AppHeader />
      <Stack
        className="px-4 py-6 sm:px-6"
        sx={{
          minHeight: {
            sm: `calc(100vh - 64px)`,
            xs: `calc(100vh - 56px)`,
          },
        }}
      >
        <Routes>
          {Object.values(privateRoute).map(({ component: Element, path, ...route }) => {
            const requiredPermission = "requiredPermission" in route ? route.requiredPermission : ""
            const notSigninYet = !permissions
            const notRequiredPermission = !requiredPermission
            const hasPermission = permissions?.includes(requiredPermission!)
            return notSigninYet || notRequiredPermission || hasPermission ? (
              <Route element={<Element />} key={path} path={path} />
            ) : null
          })}
          <Route element={<Navigate to={homePath} />} path="*" />
        </Routes>
      </Stack>
    </main>
  )
}

export default PrivateLayout
