import { Center } from "components/common"
import { useHomePath } from "hooks"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Navigate, Route, Routes, useNavigate, useSearchParams } from "react-router-dom"
import { profileSelector } from "reducers/profileSlice"
import { authRoute } from "routes"

const AuthLayout = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isLoggedIn } = useSelector(profileSelector)
  const homePath = useHomePath()

  useEffect(() => {
    if (isLoggedIn) {
      const callbackUrl = searchParams.get("callbackUrl")
      navigate(callbackUrl ?? homePath, { replace: true })
    }
  }, [isLoggedIn, homePath, searchParams, navigate])

  return (
    <main>
      <Center className="h-screen bg-black/5">
        <Routes>
          {Object.values(authRoute).map(({ component: Element, path }) => (
            <Route element={<Element />} key={path} path={path} />
          ))}
          <Route element={<Navigate to={authRoute.login.url} />} path="*" />
        </Routes>
      </Center>
    </main>
  )
}

export default AuthLayout
