import {
  ArrowDropDown,
  ArrowRight,
  BedroomBabyOutlined,
  HealthAndSafetyOutlined,
  LocalGasStation,
  Settings,
  ShieldMoonOutlined,
  ViewAgendaOutlined,
} from "@mui/icons-material"
import { alpha, Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material"
import { P } from "models/Permissions"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { profileSelector } from "reducers/profileSlice"
import { privateRoute } from "routes"

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  ".MuiListItemIcon-root": {
    marginRight: 8,
    minWidth: 0,
  },
  ".MuiListItemText-primary": {
    fontWeight: 700,
  },
  "&.MuiListItemButton-root.Mui-selected": {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  "&.MuiListItemButton-root.MuiButtonBase-root:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
  },
  borderRadius: 8,
}))

type SubMenuType = {
  name?: JSX.Element | string
  path: string
}

type MenuItemProps = {
  icon?: JSX.Element
  items?: SubMenuType[]
  name?: JSX.Element | string
  path: string
}

const MenuItem = ({ icon, items, name, path }: MenuItemProps) => {
  const location = useLocation()
  const [open, setOpen] = useState(location.pathname.startsWith(path))

  const isHome = path === "/"
  const isContain = location.pathname.startsWith(path)
  const isSelected = isHome ? location.pathname === path : isContain

  return (
    <Box>
      {items ? (
        <StyledListItem onClick={() => setOpen(!open)} selected={isContain}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{name}</ListItemText>
          {!open ? <ArrowRight /> : <ArrowDropDown />}
        </StyledListItem>
      ) : (
        <Link to={path}>
          <StyledListItem selected={isSelected}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </StyledListItem>
        </Link>
      )}

      {items && (
        <Collapse in={open}>
          <List
            className="ml-4 mt-2 flex flex-col gap-2"
            disablePadding
            sx={{
              ".Mui-selected > .MuiListItemIcon-root:before": {
                opacity: 1,
              },
              ".MuiListItemButton-root": {
                padding: "6px 16px",
              },
              ".MuiListItemIcon-root:before": {
                backgroundColor: "var(--color-primary-main)",
                borderRadius: 4,
                content: "''",
                height: 8,
                opacity: 0.2,
                width: 8,
              },
            }}
          >
            {items?.map((sub, index) => (
              <MenuItem key={index} {...sub} />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  )
}

const AppMenu = () => {
  const { isRoleAdmin, isTypeSystem, permissions = [] } = useSelector(profileSelector)

  const settings: SubMenuType[] = useMemo(() => {
    const items: SubMenuType[] = []
    if (permissions.includes(P.AGENCY_CREATE)) {
      items.push({ ...privateRoute.settingsAgencies })
    }
    if (permissions.includes(P.AIRPORT_FETCH)) {
      items.push({ ...privateRoute.settingsAirports })
    }
    if (permissions.includes(P.AIRPORT_LOUNGE_FETCH)) {
      items.push({ ...privateRoute.settingsAirportLounges })
    }
    if (permissions.includes(P.AIRPORT_LOUNGE_TICKET_FETCH)) {
      items.push({ ...privateRoute.settingsAirportLoungeFare })
    }
    if (permissions.includes(P.LOUNGE_FARE_CONFIG_FETCH)) {
      items.push({ ...privateRoute.settingsAgencyLoungeConfigs })
    }
    if (permissions.includes(P.USER_FETCH)) {
      items.push({ ...privateRoute.settingsUsers })
    }
    return items
  }, [permissions])

  return (
    <List className="flex flex-col gap-2">
      {permissions.includes(P.SPECIAL_SERVICE_READ) && (
        <MenuItem {...privateRoute.specialServices} icon={<ShieldMoonOutlined />} />
      )}
      {permissions.includes(P.SPECIAL_SERVICE_CREATE_DRAFT) && (
        <MenuItem {...privateRoute.specialServicesDraft} icon={<HealthAndSafetyOutlined />} />
      )}
      {permissions.includes(P.AIRPORT_LOUNGE_BOOKING_FETCH) && (
        <MenuItem {...privateRoute.airportLoungeBookings} icon={<ViewAgendaOutlined />} />
      )}
      {permissions.includes(P.AIRPORT_LOUNGE_BOOKING_CREATE) && (
        <MenuItem {...privateRoute.airportLoungeFormBookingsCreate} icon={<BedroomBabyOutlined />} />
      )}
      {settings.length > 0 && <MenuItem {...privateRoute.settings} icon={<Settings />} items={settings} />}
      {isRoleAdmin && isTypeSystem && <MenuItem {...privateRoute.auditLogs} icon={<LocalGasStation />} />}
    </List>
  )
}

export default AppMenu
