import { client } from "./axios"

const fetchServices = (params?: PaginateParams): Promise<PaginateResponse<SpecialService>> =>
  client.get(`/special-service-portal`, { params })
const getService = ({ id }: ByID): Promise<SpecialService> => client.get(`/special-service-portal/${id}`)
const getPlans = (body: ListSpecialServicingReq): Promise<SpecialServicingPlansRes[]> =>
  client.post(`/special-service-portal/plans`, body)
const createDraftService = (body: InsuranceDraftCreateBody): Promise<SpecialService> =>
  client.post(`/special-service-portal/insurance-drafts`, body)
const updateDraftService = (body: InsuranceDraftEditBody): Promise<SpecialService> =>
  client.post(`/special-service-portal/edit-draft`, body)
const createCoverNoteService = (body: InsuranceCoverNoteCreateBody): Promise<SpecialService> =>
  client.post(`/special-service-portal/cover-note`, body)
const updateCoverNoteService = (body: InsuranceCoverNoteEditBody): Promise<SpecialService> =>
  client.post(`/special-service-portal/edit-cover-note`, body)
const createPolicy = (body: InsurancePolicyBody): Promise<SpecialService> =>
  client.post(`/special-service-portal/policy`, body)
const cancelPolicy = (body: InsurancePolicyBody): Promise<SpecialService> =>
  client.post(`/special-service-portal/cancel-policy`, body)
const exportBooking = (body: BookingSearchParams): Promise<Blob> =>
  client.post(`/special-service-portal/export`, body, { responseType: "blob" })

const specialService = {
  cancelPolicy,
  createCoverNoteService,
  createDraftService,
  createPolicy,
  exportBooking,
  fetchServices,
  getPlans,
  getService,
  updateCoverNoteService,
  updateDraftService,
}

export default specialService
