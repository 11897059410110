import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton, Flex, SelectClearable } from "components/common"
import { enqueueSnackbar } from "notistack"
import { Controller, useForm } from "react-hook-form"
import { queryClient, specialService } from "services"
import { formatCurrency, formatDate } from "utils/common"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  action: "CANCEL" | "PAYMENT" | "REFUND"
  InsuranceItem?: SpecialService
}

const SpecialServicePolicyDialog = ({ action, InsuranceItem, onClose, onSuccess, ...props }: Props) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<InsurancePolicyBody>({
    defaultValues: {
      notes: "",
      reason: 1,
    },
  })

  const getActionName = (value: string) => {
    if (value === "PAYMENT") return "Thanh toán"
    if (value === "REFUND") return "Hoàn"
    if (value === "CANCEL") return "Huỷ"
    return ""
  }

  const createPolicyMutation = useMutation({ mutationFn: specialService.createPolicy })
  const cancelPolicyMutation = useMutation({ mutationFn: specialService.cancelPolicy })

  const onSubmit = async (values: InsurancePolicyBody) => {
    if (!InsuranceItem?.id) {
      enqueueSnackbar("Không thể thực hiện hành động với vé này.", { variant: "warning" })
      return
    }

    if (action === "PAYMENT") {
      const payload: InsurancePolicyBody = {
        insuranceId: InsuranceItem.id,
      }
      await createPolicyMutation.mutateAsync({ ...payload })
      enqueueSnackbar("Thanh toán gói bảo hiểm thành công")
    }

    if (["CANCEL", "REFUND"].includes(action)) {
      const payload: InsurancePolicyBody = {
        insuranceId: InsuranceItem.id,
        notes: values.notes,
        reason: values.reason,
      }
      await cancelPolicyMutation.mutateAsync({ ...payload })
      enqueueSnackbar(`${["REFUND"].includes(action) ? "Hoàn" : "Huỷ"} gói bảo hiểm thành công`)
    }
    queryClient.invalidateQueries({ queryKey: ["specialService.fetchServices"] })
    reset()
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{`${getActionName(action)} gói bảo hiểm`}</DialogTitle>
      <DialogContent>
        <Grid columnSpacing={6} container rowSpacing={4}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Flex className="justify-between">
                <Typography variant="h6"> Hành khách: {InsuranceItem?.passengerName} </Typography>
              </Flex>

              <Flex className="justify-between">
                <Typography variant="h6"> Gói bảo hiểm: {InsuranceItem?.planName} </Typography>
              </Flex>

              <Flex className="justify-between">
                <Typography variant="h6">
                  {" "}
                  Hành trình: {InsuranceItem?.flightJourney?.map((trip) => `${trip.from}-${trip.to}`).join(", ")}{" "}
                </Typography>
              </Flex>

              {action === "PAYMENT" && (
                <>
                  <Flex className="justify-between">
                    <Typography variant="h6">
                      {" "}
                      Số tiền cần thanh toán: {formatCurrency(InsuranceItem?.totalPrice)} VND
                    </Typography>
                  </Flex>
                  <Flex className="justify-between">
                    <Typography variant="h6">
                      {" "}
                      Thời hạn thanh toán: {formatDate(InsuranceItem?.flightJourney?.[0].boardingTime!)}
                    </Typography>
                  </Flex>
                </>
              )}
            </Stack>
          </Grid>

          {action === "REFUND" && (
            <>
              <Grid item md={6} xs={12}>
                <Controller
                  control={control}
                  name="reason"
                  render={({ field, fieldState: { error } }) => (
                    <SelectClearable fullWidth label="Lý do" required select size="small" {...field} clearable={false}>
                      <MenuItem value={1}>Thay đổi hành trình</MenuItem>
                      <MenuItem value={0}>Không xin được Visa</MenuItem>
                      <MenuItem value={2}>Khác</MenuItem>
                    </SelectClearable>
                  )}
                  rules={{
                    required: "Không được để trống, vui lòng chọn",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      label="Ghi chú"
                      multiline
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      rows={4}
                    />
                  )}
                  rules={{
                    maxLength: {
                      message: "Ghi chú tối đa 255 ký tự",
                      value: 255,
                    },
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <LoadingButton
          color={action === "PAYMENT" ? "success" : action === "REFUND" ? "warning" : "error"}
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          variant="outlined"
        >
          {`${getActionName(action)} gói`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default SpecialServicePolicyDialog
