import { LoadingButton } from "@mui/lab"
import { Button, Card, CardContent, CardHeader, Container, Stack } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Flex } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { profileSelector } from "reducers/profileSlice"
import { privateRoute } from "routes"
import { airportLoungeBookingService, airportService, queryClient } from "services"

import { BookingForm, FlightForm } from "./components"
import { convertBookingToFormValues } from "./utils"

const AirportLoungeBookingCreate = () => {
  const navigate = useNavigate()
  const { isTypeSystem, user } = useSelector(profileSelector)
  const { id } = useParams()
  const { data: booking } = useQuery({
    enabled: !!id,
    queryFn: () => airportLoungeBookingService.getBooking({ id: Number(id) }),
    queryKey: ["airportLoungeBookingService.getBooking", id],
  })
  const isCreate = !id

  const { data: airportQuery } = useQuery({
    enabled: !!user?.agency.id,
    queryFn: () => airportService.fetchAirportsAndFares({ agencyId: user?.agency.id || undefined }),
    queryKey: ["airportService.fetchAirportsAndFares", user],
  })
  const { items: airports } = airportQuery ?? {}

  const airportLounges = useMemo(() => {
    return (airports ?? []).flatMap((airport) =>
      airport.airportLounges.map((airportLounge) => ({
        ...airportLounge,
        airport,
      })),
    )
  }, [airports])

  const airportLoungeFares = useMemo(() => {
    return airportLounges.flatMap((airportLounge) =>
      airportLounge.airportLoungeFares.map((airportLoungeFare) => ({
        ...airportLoungeFare,
        airportLounge,
      })),
    )
  }, [airportLounges])

  const form = useForm<AirportLoungeBookingCreateBody>({
    defaultValues: convertBookingToFormValues(),
  })
  const {
    formState: { defaultValues, isSubmitting },
    handleSubmit,
    reset,
    setValue,
  } = form

  const nextBookingFares = useMemo(() => {
    return airportLoungeFares
      .filter((airportLoungeFare) => airportLoungeFare.airportLounge.id === booking?.airportLounge.id)
      .map((airportLoungeFare) => {
        const bookingFare = booking?.bookingFares.find(
          (bookingFare) => bookingFare.airportLoungeFare.id === airportLoungeFare.id,
        )
        return {
          airportLoungeFareId: airportLoungeFare.id,
          count: bookingFare?.count ?? 0,
        }
      })
  }, [booking, airportLoungeFares])

  useEffect(() => {
    reset(convertBookingToFormValues(booking, nextBookingFares))
  }, [reset, booking, nextBookingFares])

  useEffect(() => {
    if (user && !isTypeSystem) {
      setValue("agencyId", user.agency.id)
    }
  }, [setValue, isTypeSystem, user, booking])

  const createBookingMutation = useMutation({
    mutationFn: airportLoungeBookingService.createBooking,
    onSuccess: (booking) => {
      enqueueSnackbar("Đã đặt phòng chờ thành công")
      navigate(privateRoute.airportLoungeBookingsView.url(booking))
      queryClient.invalidateQueries({ queryKey: ["airportLoungeBookingService.fetchBookings"] })
    },
  })
  const updateBookingMutation = useMutation({
    mutationFn: airportLoungeBookingService.updateBooking,
    onSuccess: (booking) => {
      enqueueSnackbar("Đã cập nhật phòng chờ thành công")
      navigate(privateRoute.airportLoungeBookingsView.url(booking))
      queryClient.invalidateQueries({ queryKey: ["airportLoungeBookingService.fetchBookings"] })
      queryClient.invalidateQueries({ queryKey: ["airportLoungeBookingService.getBooking"] })
    },
  })

  const onSubmit = async (values: AirportLoungeBookingCreateBody) => {
    const { bookingFares } = values
    const count = bookingFares.reduce((sum, fare) => sum + fare.count, 0)
    if (count === 0) {
      enqueueSnackbar("Vui lòng chọn ít nhất một loại vé", { variant: "error" })
      return
    }
    if (isCreate) {
      await createBookingMutation.mutateAsync({ ...values })
    } else {
      await updateBookingMutation.mutateAsync({ ...values, id: Number(id) })
    }
  }

  return (
    <Container className="max-sm:px-0">
      <FormProvider {...form}>
        <Stack gap={3}>
          <Card>
            <CardHeader title="Thông tin đặt phòng chờ" />
            <CardContent>
              <BookingForm
                airportLoungeFares={airportLoungeFares}
                airportLounges={airportLounges}
                airports={airports}
                booking={booking}
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Thông tin vé máy bay" />
            <CardContent>
              <FlightForm />
            </CardContent>
          </Card>

          <Flex className="justify-center gap-4 sm:gap-6">
            <Button color="inherit" onClick={() => reset(defaultValues)}>
              Đặt lại
            </Button>
            <LoadingButton
              className="px-12"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              {isCreate ? "Đặt dịch vụ" : "Cập nhật"}
            </LoadingButton>
          </Flex>
        </Stack>
      </FormProvider>
    </Container>
  )
}

export default AirportLoungeBookingCreate
