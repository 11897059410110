import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Stack, TextField } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { agencyService, queryClient } from "services"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  agency?: Agency
}

const AgencyDialog = ({ agency, onClose, onSuccess, ...props }: Props) => {
  const isCreate = !agency
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<AgencyCreateBody>()

  useEffect(() => {
    reset({
      code: agency?.code ?? "",
      name: agency?.name ?? "",
    })
  }, [reset, props.open, agency])

  const createAgencyMutation = useMutation({ mutationFn: agencyService.createAgency })

  const onSubmit = async (values: AgencyCreateBody) => {
    if (isCreate) {
      if (!values.code?.trim()) {
        values.code = undefined
      }
      await createAgencyMutation.mutateAsync({ ...values })
      enqueueSnackbar("Tạo mới đại lý thành công")
    }
    queryClient.invalidateQueries({ queryKey: ["agencyService.fetchAgencies"] })
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="xs" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{isCreate ? "Tạo đại lý" : "Chi tiết đại lý"}</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Tên đại lý"
                required
                {...field}
                error={!!error}
                helperText={error?.message}
                InputProps={{ readOnly: !isCreate }}
              />
            )}
            rules={{
              required: "Tên đại lý không được để trống",
            }}
          />
          <Controller
            control={control}
            name="code"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Mã đại lý"
                {...field}
                error={!!error}
                helperText={error?.message}
                InputProps={{ readOnly: !isCreate }}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        {isCreate && (
          <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
            Tạo mới
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AgencyDialog
