import { FileDownloadOutlined, Search } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Autocomplete, Box, BoxProps, Grid, InputAdornment, MenuItem, Stack, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Flex, SelectClearable } from "components/common"
import { saveAs } from "file-saver"
import { P } from "models/Permissions"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useDebounce } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { airportLoungeBookingService, airportService } from "services"

type Props = BoxProps & {
  onSearchChange?: (values: BookingSearchParams) => void
}

const AirportLoungeBookingSearch = ({ onSearchChange, ...props }: Props) => {
  const { isTypeProvider, permissions = [] } = useSelector(profileSelector)

  const { control, setValue, watch } = useForm<BookingSearchParams>({
    defaultValues: {
      agencyId: "",
      airportId: "",
      airportLoungeId: "",
      createdFrom: null,
      createdTo: null,
      searchText: "",
      status: "",
    },
  })
  const formValues = watch()
  const { agencyId, airportId } = formValues
  const [searchParams, setSearchParams] = useState<BookingSearchParams>({})

  useDebounce(
    () => {
      const searchParams: BookingSearchParams = { ...formValues }

      if (searchParams.agencyId === "") {
        searchParams.agencyId = undefined
      }
      if (searchParams.airportId === "") {
        searchParams.airportId = undefined
      }
      if (searchParams.airportLoungeId === "") {
        searchParams.airportLoungeId = undefined
      }
      if (searchParams.createdFrom) {
        searchParams.createdFrom = searchParams.createdFrom.toISODate()
      }
      if (searchParams.createdTo) {
        searchParams.createdTo = searchParams.createdTo.toISODate()
      }
      if (searchParams.status === "") {
        searchParams.status = undefined
      }
      setSearchParams(searchParams)
      onSearchChange?.(searchParams)
    },
    100,
    [JSON.stringify(formValues)],
  )

  const { data: airportQuery } = useQuery({
    queryFn: () => airportService.fetchAirportsAndFares({ agencyId: agencyId || undefined }),
    queryKey: ["airportService.fetchAirportsAndFares", agencyId],
  })
  const { items: airports = [] } = airportQuery ?? {}

  const airportLounges = airports.flatMap((airport) =>
    airport.airportLounges.map((airportLounge) => ({
      ...airportLounge,
      airport,
    })),
  )

  const exportBookingMutation = useMutation({ mutationFn: airportLoungeBookingService.exportBooking })

  const handleExport = async () => {
    const blob = await exportBookingMutation.mutateAsync(searchParams)
    saveAs(blob, "Danh_Sach_Dat_Cho.xlsx")
  }

  return (
    <Flex gap={6} {...props}>
      <Grid columnSpacing={4} container rowSpacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="searchText"
            render={({ field }) => (
              <TextField
                fullWidth
                placeholder="Tìm kiếm mã đặt vé, khách hàng..."
                size="small"
                {...field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="createdFrom"
            render={({ field }) => (
              <DatePicker
                format="dd/MM/yyyy"
                label="Từ ngày"
                {...field}
                closeOnSelect
                slotProps={{
                  field: { clearable: true, onClear: () => setValue("createdFrom", null) },
                  textField: { fullWidth: true, size: "small" },
                }}
              />
            )}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="createdTo"
            render={({ field }) => (
              <DatePicker
                format="dd/MM/yyyy"
                label="Đến ngày"
                {...field}
                closeOnSelect
                slotProps={{
                  field: { clearable: true, onClear: () => setValue("createdTo", null) },
                  textField: { fullWidth: true, size: "small" },
                }}
              />
            )}
          />
        </Grid>

        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="airportId"
            render={({ field }) => (
              <SelectClearable
                fullWidth
                label="Sân bay"
                select
                size="small"
                {...field}
                onChange={(event) => {
                  field.onChange(event)
                  setValue("airportLoungeId", "")
                }}
                onClear={() => {
                  setValue("airportId", "")
                  setValue("airportLoungeId", "")
                }}
              >
                {airports.map((airport) => (
                  <MenuItem key={airport.id} value={airport.id}>
                    {airport.name}
                  </MenuItem>
                ))}
              </SelectClearable>
            )}
          />
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="airportLoungeId"
            render={({ field: { onChange, value: airportLoungeId } }) => {
              const selectedLounge = airportLounges.find((airportLounge) => airportLounge.id === airportLoungeId)
              return (
                <Autocomplete<AirportLounge>
                  autoHighlight
                  filterOptions={(options, state) =>
                    options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
                  }
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, airportLounge) => {
                    onChange(airportLounge?.id)
                  }}
                  options={airportLounges.filter(
                    (airportLounge) => !airportId || airportLounge.airport.id === airportId,
                  )}
                  renderInput={(params) => (
                    <TextField label="Phòng chờ" {...params} InputLabelProps={{ shrink: true }} size="small" />
                  )}
                  renderOption={(props, option) => (
                    <Stack component="li" style={{ alignItems: "flex-start" }} {...props}>
                      <Box>{option.name}</Box>
                      <Box className="text-sm text-gray-600">
                        {option.airport.name} - {option.isDomestic ? "Nội địa" : "Quốc tế"}
                      </Box>
                    </Stack>
                  )}
                  value={selectedLounge ?? null}
                />
              )
            }}
          />
        </Grid>

        {!isTypeProvider && (
          <Grid item lg={4} md={6} xs={12}>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <SelectClearable
                  fullWidth
                  label="Trạng thái"
                  select
                  size="small"
                  {...field}
                  onClear={() => setValue("status", "")}
                >
                  <MenuItem value="PAID">Đã thanh toán</MenuItem>
                  <MenuItem value="CHECKIN">Đã check-in</MenuItem>
                </SelectClearable>
              )}
            />
          </Grid>
        )}
      </Grid>
      <Stack width={240}>
        {permissions.includes(P.AIRPORT_LOUNGE_BOOKING_EXPORT) && (
          <LoadingButton
            loading={exportBookingMutation.isPending}
            onClick={handleExport}
            startIcon={<FileDownloadOutlined />}
            variant="outlined"
          >
            Xuất Excel
          </LoadingButton>
        )}
      </Stack>
    </Flex>
  )
}

export default AirportLoungeBookingSearch
