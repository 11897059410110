import { InfoOutlined } from "@mui/icons-material"
import { Button, Chip, Stack, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { Flex, Text } from "components/common"
import { useSearch } from "hooks"
import { P } from "models/Permissions"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useToggle } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { specialService } from "services"
import { compareDateBySubtract, formatCurrency, formatDateTime } from "utils/common"

import {
  InsuranceCreateCoverNoteDialog,
  InsuranceEditDraftDialog,
  SpecialServicePolicyDialog,
  SpecialServiceSearch,
  SpecialServiceView,
} from "."

const SUBTRACT_1_HOURS_BY_SECONDS_INS: SubtractParams = { subtractType: "second", subtractValue: 3600 }
const CANNOT_HANDLE_INSURANCE_SERVICE = "Hành trình sắp đến giờ khởi hành, không thể thực hiện thay đổi !"

const SpecialServiceList = () => {
  const { dataSearch, onSearchChange } = useSearch()
  const { id, isRoleUser, isTypeAgency, permissions = [] } = useSelector(profileSelector)

  const [isOpenView, onToggleView] = useToggle(false)
  const [isOpenEditDraft, onToggleEditDraft] = useToggle(false)
  const [isOpenPolicy, onTogglePolicy] = useToggle(false)
  const [isOpenCreateCoverNote, onToggleCreateCoverNote] = useToggle(false)
  const [policyAction, setPolicyAction] = useState<"CANCEL" | "PAYMENT" | "REFUND">("PAYMENT")

  const [specialServiceSelected, setSpecialServiceSelected] = useState<SpecialService>()

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => specialService.fetchServices(dataSearch),
    queryKey: ["specialService.fetchServices", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  const canModifyByUser = (item: SpecialService): boolean => {
    if (item.source !== "PORTAL") {
      return false
    }
    if (isTypeAgency) {
      if (isRoleUser) {
        return id === item?.createdBy?.id
      }
      return true
    }
    return true
  }

  const handleOpenEditDraft = (item: SpecialService) => {
    if (!item.agencyName || !item.agencyCode || !item.agencyEmail) {
      enqueueSnackbar("Không thể thực hiện hành động với vé này", { variant: "warning" })
      return
    }
    const departureTime = item.flightJourney[0].boardingTime
    const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)
    if (isBefore || isSame) {
      enqueueSnackbar(CANNOT_HANDLE_INSURANCE_SERVICE, { variant: "error" })
      return
    }
    setSpecialServiceSelected(item)
    onToggleEditDraft(true)
  }

  const handleOpenCoverNote = (item: SpecialService) => {
    if (!item.agencyName || !item.agencyCode || !item.agencyEmail) {
      enqueueSnackbar("Không thể thực hiện hành động với vé này", { variant: "warning" })
      return
    }
    const departureTime = item.flightJourney[0].boardingTime
    const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)
    if (isBefore || isSame) {
      enqueueSnackbar(CANNOT_HANDLE_INSURANCE_SERVICE, { variant: "error" })
      return
    }
    setSpecialServiceSelected(item)
    onToggleCreateCoverNote(true)
  }

  const handleOpenPayment = (item: SpecialService) => {
    const departureTime = item.flightJourney[0].boardingTime
    const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)
    if (isBefore || isSame) {
      enqueueSnackbar(CANNOT_HANDLE_INSURANCE_SERVICE, { variant: "error" })
      return
    }
    setSpecialServiceSelected(item)
    onTogglePolicy(true)
    setPolicyAction("PAYMENT")
  }

  const handleOpenCancelOrRefund = (item: SpecialService) => {
    const departureTime = item.flightJourney[0].boardingTime
    if (["PAID"].includes(item.status)) {
      const { isBefore, isSame } = compareDateBySubtract(departureTime, { subtractType: "day" })
      if (isBefore || isSame) {
        enqueueSnackbar("Gói bảo hiểm đã đến ngày hiệu lực, không thể hoàn gói !", { variant: "error" })
        return
      }
    } else {
      const { isBefore, isSame } = compareDateBySubtract(departureTime, SUBTRACT_1_HOURS_BY_SECONDS_INS)
      if (isBefore || isSame) {
        enqueueSnackbar(CANNOT_HANDLE_INSURANCE_SERVICE, { variant: "error" })
        return
      }
    }
    setSpecialServiceSelected(item)
    onTogglePolicy(true)
    setPolicyAction(["PAID"].includes(item.status) ? "REFUND" : "CANCEL")
  }

  return (
    <Stack>
      <Flex className="justify-between">
        <Typography variant="h4">Dịch vụ bảo hiểm</Typography>
      </Flex>
      <SpecialServiceSearch className="max-md:hidden" marginTop={3} onSearchChange={onSearchChange} />

      <DataGrid
        columns={([] as GridColDef<SpecialService>[])
          .concat([
            {
              field: "bookingCode",
              flex: 1,
              headerName: "Mã đặt chỗ",
              minWidth: 250,
              renderCell: ({ row: item }) => (
                <Stack>
                  <Text
                    color="primary"
                    fontWeight="bold"
                    onClick={() => {
                      setSpecialServiceSelected(item)
                      onToggleView(true)
                    }}
                  >
                    {item.bookingCode}
                  </Text>
                  <Text>{item.flightJourney.map((flight) => `${flight.from} - ${flight.to}`).join(", ")}</Text>
                </Stack>
              ),
              sortable: false,
            },
          ])
          .concat([
            {
              field: "planName",
              flex: 1,
              headerName: "Gói",
              minWidth: 180,
              sortable: false,
            },
            {
              field: "contactName",
              flex: 1,
              headerName: "Khách hàng",
              minWidth: 180,
              renderCell: ({ row: item }) => item.passengerName,
              sortable: false,
            },
            {
              field: "price",
              flex: 1,
              headerName: "Giá bảo hiểm",
              minWidth: 140,
              renderCell: ({ row: item }) =>
                `${formatCurrency(item.price)} ${item.currency ? item.currency.toUpperCase() : ""}`,
              sortable: false,
            },
            {
              field: "createdAt",
              flex: 1,
              headerName: "Ngày đặt",
              minWidth: 140,
              renderCell: ({ row: item }) => formatDateTime(item.createdAt),
              sortable: false,
            },
            {
              field: "status",
              flex: 1,
              headerName: "Trạng thái",
              minWidth: 160,
              renderCell: ({ row: item }) => {
                if (item.status === "PENDING_PAYMENT") return <Chip color="info" label="Chưa thanh toán" />
                if (item.status === "PAID") return <Chip color="success" label="Đã thanh toán" />
                if (item.status === "REFUND") return <Chip color="warning" label="Đã hoàn" />
                if (item.status === "CANCEL") return <Chip color="error" label="Đã huỷ" />
                if (item.status === "PENDING_SUBMISSION") return <Chip label="Chờ xác nhận" />
              },
              sortable: false,
            },
            {
              align: "left",
              field: "actions",
              headerName: "",
              minWidth: 200,
              renderCell: ({ row: item }) => {
                return (
                  <Flex className="flex-nowrap gap-2">
                    <Button
                      color="inherit"
                      onClick={() => {
                        setSpecialServiceSelected(item)
                        onToggleView(true)
                      }}
                      size="small"
                    >
                      <InfoOutlined />
                    </Button>

                    {canModifyByUser(item) &&
                      permissions.includes(P.SPECIAL_SERVICE_UPDATE_DRAFT) &&
                      ["PENDING_SUBMISSION"].includes(item.status) && (
                        <Button
                          className="text-nowrap"
                          onClick={() => {
                            handleOpenEditDraft(item)
                          }}
                          size="small"
                          variant="outlined"
                        >
                          Đổi gói
                        </Button>
                      )}

                    {canModifyByUser(item) &&
                      permissions.includes(P.SPECIAL_SERVICE_CREATE_COVER_NOTE) &&
                      ["PENDING_SUBMISSION"].includes(item.status) && (
                        <Button
                          className="text-nowrap"
                          onClick={() => {
                            handleOpenCoverNote(item)
                          }}
                          size="small"
                          variant="outlined"
                        >
                          Tạo cover note
                        </Button>
                      )}

                    {canModifyByUser(item) &&
                      permissions.includes(P.SPECIAL_SERVICE_UPDATE_COVER_NOTE) &&
                      item.passport &&
                      ["PENDING_PAYMENT"].includes(item.status) && (
                        <Button
                          className="text-nowrap"
                          color="primary"
                          onClick={() => {
                            handleOpenCoverNote(item)
                          }}
                          size="small"
                        >
                          Chỉnh sửa
                        </Button>
                      )}

                    {canModifyByUser(item) &&
                      permissions.includes(P.SPECIAL_SERVICE_PAYMENT) &&
                      ["PENDING_PAYMENT"].includes(item.status) && (
                        <Button
                          className="text-nowrap"
                          color="success"
                          onClick={() => {
                            handleOpenPayment(item)
                          }}
                          size="small"
                        >
                          Thanh toán
                        </Button>
                      )}

                    {canModifyByUser(item) &&
                      (permissions.includes(P.SPECIAL_SERVICE_CANCEL) ||
                        permissions.includes(P.SPECIAL_SERVICE_REFUND)) &&
                      ["PAID", "PENDING_PAYMENT"].includes(item.status) && (
                        <Button
                          className="text-nowrap"
                          color={["PAID"].includes(item.status) ? "warning" : "error"}
                          onClick={() => {
                            handleOpenCancelOrRefund(item)
                          }}
                          size="small"
                        >
                          {["PAID"].includes(item.status) ? "Hoàn gói" : "Huỷ gói"}
                        </Button>
                      )}
                  </Flex>
                )
              },
              sortable: true,
              width: 128 - 52,
            },
          ])}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />

      <SpecialServiceView
        onClose={() => onToggleView(false)}
        open={isOpenView}
        specialService={specialServiceSelected}
      />
      <InsuranceCreateCoverNoteDialog
        InsuranceItem={specialServiceSelected}
        onClose={() => onToggleCreateCoverNote(false)}
        open={isOpenCreateCoverNote}
      />
      <InsuranceEditDraftDialog
        InsuranceItem={specialServiceSelected}
        onClose={() => onToggleEditDraft(false)}
        open={isOpenEditDraft}
      />
      <SpecialServicePolicyDialog
        action={policyAction}
        InsuranceItem={specialServiceSelected}
        onClose={() => onTogglePolicy(false)}
        open={isOpenPolicy}
      />
    </Stack>
  )
}

export default SpecialServiceList
