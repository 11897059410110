import { CameraAlt } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { Center } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useEffect, useRef, useState } from "react"
import { Camera, CameraType } from "react-camera-pro"
import { useParams } from "react-router-dom"
import { useToggle } from "react-use"
import { airportLoungeBookingService, queryClient } from "services"
import { convertDataUrlToFile } from "utils/common"

type DialogController = PopupController & DialogProps

type Props = DialogController & {}

const AirportLoungeBookingCheckinDialog = ({ onClose, onSuccess, ...props }: Props) => {
  const params = useParams()
  const [isOpen, onToggle] = useToggle(false)

  useEffect(() => {
    if (props.open) {
      onToggle(false)
    }
  }, [props.open, onToggle])

  const camera = useRef<CameraType>(null)
  const [image, setImage] = useState<string>()

  const checkinMutation = useMutation({
    mutationFn: airportLoungeBookingService.checkinBookingEvident,
  })

  const handleSubmit = async () => {
    const formData = new FormData()
    const file = convertDataUrlToFile(image!)
    formData.append("file", file)
    await checkinMutation.mutateAsync({
      formData,
      id: Number(params.id),
    })
    onClose()
    enqueueSnackbar("Đã check-in vé thành công")
    queryClient.invalidateQueries({ queryKey: ["airportLoungeBookingService.fetchBookings"] })
    queryClient.invalidateQueries({ queryKey: ["airportLoungeBookingService.getBooking"] })
  }

  return (
    <Dialog fullScreen={isOpen} maxWidth="xs" {...props}>
      <DialogTitle className="text-success">{isOpen ? "Chụp ảnh khách" : "Check-In thành công"}</DialogTitle>
      <DialogContent>
        {isOpen ? (
          <Center
            className="relative h-full w-full bg-black/5"
            sx={{
              "& video": {
                inset: 0,
                position: "absolute",
              },
            }}
          >
            {image ? <img src={image} /> : <Camera errorMessages={{}} facingMode="environment" ref={camera} />}
          </Center>
        ) : (
          <Typography color="success">Bạn có muốn chụp ảnh khách hàng không?</Typography>
        )}
      </DialogContent>
      <DialogActions>
        {image ? <Button onClick={() => setImage("")}>Chụp lại</Button> : <Button onClick={onClose}>Đóng</Button>}
        {isOpen ? (
          image ? (
            <LoadingButton
              className="w-[180px]"
              loading={checkinMutation.isPending}
              onClick={handleSubmit}
              variant="contained"
            >
              Hoàn thành
            </LoadingButton>
          ) : (
            <Button
              className="w-[180px]"
              onClick={() => {
                try {
                  setImage(camera.current?.takePhoto() ?? "")
                } catch (e) {
                  setImage(" ")
                }
              }}
              startIcon={<CameraAlt />}
              variant="contained"
            >
              Chụp
            </Button>
          )
        ) : (
          <Button className="w-[180px]" onClick={() => onToggle(true)} startIcon={<CameraAlt />} variant="contained">
            Chụp ảnh khách
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AirportLoungeBookingCheckinDialog
