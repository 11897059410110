import { client } from "./axios"

const createAirport = (body: AirportCreateBody): Promise<Airport> => client.post(`/airports`, body)
const deleteAirport = ({ id }: ByID): Promise<null> => client.delete(`/airports/${id}`)
const fetchAirports = (params?: PaginateParams): Promise<PaginateResponse<Airport>> =>
  client.get(`/airports`, { params })
const fetchAirportsAndFares = (params?: AgencyParams): Promise<PaginateResponse<Airport>> =>
  client.get(`/airports/agency_fare_prices`, { params })
const getAirport = ({ id }: ByID): Promise<Airport> => client.get(`/airports/${id}`)
const updateAirport = ({ id, ...body }: AirportUpdateBody): Promise<Airport> => client.patch(`/airports/${id}`, body)

const airportService = {
  createAirport,
  deleteAirport,
  fetchAirports,
  fetchAirportsAndFares,
  getAirport,
  updateAirport,
}

export default airportService
