import { client } from "./axios"

const createLoungeFare = (body: AirportLoungeFareCreateBody): Promise<AirportLoungeFare> =>
  client.post(`/airport_lounge_fares`, body)
const deleteLoungeFare = ({ id }: ByID): Promise<null> => client.delete(`/airport_lounge_fares/${id}`)
const fetchLoungeFare = (params?: PaginateParams): Promise<PaginateResponse<AirportLoungeFare>> =>
  client.get(`/airport_lounge_fares`, { params })
const getLoungeFare = ({ id }: ByID): Promise<AirportLoungeFare> => client.get(`/airport_lounge_fares/${id}`)
const updateLoungeFare = ({ id, ...body }: AirportLoungeFareUpdateBody): Promise<AirportLoungeFare> =>
  client.patch(`/airport_lounge_fares/${id}`, body)

const airportLoungeFareService = {
  createLoungeFare,
  deleteLoungeFare,
  fetchLoungeFare,
  getLoungeFare,
  updateLoungeFare,
}

export default airportLoungeFareService
