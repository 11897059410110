import LocationOnIcon from "@mui/icons-material/LocationOn"
import { Autocomplete, CircularProgress, debounce, Grid, Stack, TextField, Typography } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Flex } from "components/common"
import { useMemo, useState } from "react"
import { useController, useFormContext } from "react-hook-form"

interface Props {
  initialValue?: AirportIata
  label: string
  name: string
  rules?: any
}

const fetchAirports = async (searchText: string): Promise<AirportIata[]> => {
  const response = await fetch(`https://api-dev.airdata.site/cms/api/public/airports-iata?searchText=${searchText}`)
  const data = await response.json()
  return data.items
}

export default function AirportAutocomplete({ label, name, rules }: Props) {
  const { control } = useFormContext()
  const {
    field: { onChange, value: airport },
    fieldState: { error },
  } = useController({ control, name, rules })

  const [inputValue, setInputValue] = useState("")
  const debouncedInputValue = useMemo(() => debounce(setInputValue, 400), [])

  const { data: options = [], isPending } = useQuery({
    queryFn: () => fetchAirports(inputValue),
    queryKey: ["fetchAirports", inputValue],
  })

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => (option.iata ? `${option.iata} - ${option.name}` : "")}
      id="airport-autocomplete"
      includeInputInList
      isOptionEqualToValue={(option, airport) => option.id === airport.id}
      noOptionsText={isPending ? "Đang tải..." : "Vui lòng nhập vào..."}
      onBlur={(event) => {
        const target = event.target as HTMLInputElement
        if (!options.some((option) => option.iata === target.value)) {
          debouncedInputValue("")
        }
      }}
      onChange={(event, airport: AirportIata | null) => {
        onChange(airport)
      }}
      onInputChange={(event, newInputValue) => {
        debouncedInputValue(newInputValue)
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          fullWidth
          helperText={error ? error.message : null}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isPending ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          required
        />
      )}
      renderOption={(props, option) => (
        <Stack component="li" style={{ alignItems: "flex-start" }} {...props}>
          <Grid alignItems="center" container>
            <Grid item sx={{ display: "flex", width: 34 }}>
              <LocationOnIcon sx={{ color: "text.secondary" }} />
            </Grid>
            <Grid item sx={{ width: "calc(100% - 34px)", wordWrap: "break-word" }}>
              <Flex alignItems={"center"} sx={{ fontWeight: "bold" }}>
                <Typography className="text-primary-main" fontWeight={800} variant="h6">
                  {option.iata}
                </Typography>
                <Typography color="black" fontWeight={600} ml={1} noWrap variant="body1">
                  {option.city || option.name}
                </Typography>
              </Flex>

              <Typography color="text.secondary" variant="body2">
                {option.city ? `${option.name}, ` : ""} {option.country || option.countryName}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      )}
      value={airport}
    />
  )
}
