import { Edit, FileUploadOutlined } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { BoxProps, Button, Card, CardContent, CardHeader, Container, Stack } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Flex, Span, Text } from "components/common"
import { P } from "models/Permissions"
import { enqueueSnackbar } from "notistack"
import { ReactNode, useRef } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { useToggle } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { privateRoute } from "routes"
import { airportLoungeBookingService, queryClient } from "services"
import { formatDateTime, reduceImageFile } from "utils/common"

import { AirportLoungeBookingCheckinDialog } from "."

type LineInfoProps = BoxProps & {
  label?: ReactNode
  value?: ReactNode
}

const LineInfo = ({ label, value, ...props }: LineInfoProps) => {
  return (
    <Flex columnGap={3} flexWrap="wrap" justifyContent="space-between" {...props}>
      {typeof label === "string" ? (
        <Text fontWeight={600} whiteSpace="nowrap">
          {label.trim() || "-"}
        </Text>
      ) : (
        label
      )}
      {typeof value === "string" ? (
        <Text className="max-sm:text-[cadetblue]" flex="1" textAlign="right" whiteSpace="pre-line">
          {value.trim() || "-"}
        </Text>
      ) : (
        value
      )}
    </Flex>
  )
}

const AirportLoungeBookingView = () => {
  const { id } = useParams()
  const { isTypeSystem, permissions = [] } = useSelector(profileSelector)
  const [isOpen, onToggle] = useToggle(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const { data: booking } = useQuery({
    queryFn: () => airportLoungeBookingService.getBooking({ id: Number(id) }),
    queryKey: ["airportLoungeBookingService.getBooking", id],
  })
  const isCheckin = booking?.status === "CHECKIN"

  const checkinBookingMutation = useMutation({
    mutationFn: airportLoungeBookingService.checkinBooking,
  })
  const checkinEvidentMutation = useMutation({
    mutationFn: airportLoungeBookingService.checkinBookingEvident,
  })

  const handleCheckin = async () => {
    if (isCheckin) return
    await checkinBookingMutation.mutateAsync({ id: Number(id) })
    onToggle(true)
    queryClient.invalidateQueries({ queryKey: ["airportLoungeBookingService.fetchBookings"] })
    queryClient.invalidateQueries({ queryKey: ["airportLoungeBookingService.getBooking"] })
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const newFile = (await reduceImageFile(file)) as File
      const formData = new FormData()
      formData.append("file", newFile)
      await checkinEvidentMutation.mutateAsync({
        formData,
        id: Number(booking?.id),
      })
      enqueueSnackbar("Đã upload thành công")
      queryClient.invalidateQueries({ queryKey: ["airportLoungeBookingService.getBooking"] })
    }
  }

  return (
    <Container className="max-sm:px-0">
      <Flex className="flex-col gap-6 md:flex-row md:gap-12">
        <Card className="flex-1 md:max-w-lg">
          <CardHeader
            action={
              permissions.includes(P.AIRPORT_LOUNGE_BOOKING_UPDATE) &&
              booking && (
                <Link to={privateRoute.airportLoungeFormBookingsUpdate.url(booking)}>
                  <Button size="small" startIcon={<Edit />}>
                    Chỉnh sửa
                  </Button>
                </Link>
              )
            }
            title="Chi tiết vé"
          />
          <CardContent>
            <Text className="mb-1 text-lg font-bold">Thông tin đặt phòng chờ</Text>
            {isTypeSystem && <LineInfo label="1. Đại lý" value={booking?.agency.name} />}
            <LineInfo label="2. Tên người mua" value={booking?.contactName} />
            <LineInfo label="3. Số điện thoại" value={booking?.contactPhone} />
            <LineInfo label="4. Email" value={booking?.contactEmail} />
            <LineInfo label="5. Thời gian sử dụng" value={formatDateTime(booking?.servingFrom)} />
            <LineInfo label="6. Sân bay" value={booking?.airport.name} />
            <LineInfo label="7. Phòng chờ" value={booking?.airportLounge.name} />
            <LineInfo
              label="8. Loại vé và số lượng"
              value={
                <Stack>
                  {booking?.bookingFares.map((bookingFare) => (
                    <Flex gap={3} justifyContent="space-between" key={bookingFare.id} marginLeft={2}>
                      <Text>{bookingFare.airportLoungeFare.name}</Text>
                      <Text>x{bookingFare.count}</Text>
                    </Flex>
                  ))}
                </Stack>
              }
            />
            {isCheckin && <LineInfo label="9. Thời gian Check-In" value={formatDateTime(booking.checkinAt)} />}

            <Text className="mb-1 mt-4 text-lg font-bold">Thông tin vé máy bay</Text>
            <LineInfo label="1. Mã PNR" value={booking?.flightCode} />
            <LineInfo label="2. Hành trình" value={booking?.flightTrip} />
            <LineInfo label="3. Ngày bay" value={booking?.flightTime ? formatDateTime(booking.flightTime) : ""} />
            <LineInfo label="4. Số hiệu chuyến bay" value={booking?.flightNumber} />
            <LineInfo label="5. Remark" value={booking?.flightRemark} />
          </CardContent>
        </Card>

        <Stack alignItems="center" gap={0.5}>
          <Text className="text-xl font-bold">Mã QR</Text>
          <Text>
            Mã đặt vé: <Span className="font-bold text-primary-main">{booking?.bookingCode}</Span>
          </Text>
          <img className="w-[240px] border" src={booking?.qrCode} />

          <Stack className="sticky bottom-0 w-full bg-white/80 py-3 md:mt-4">
            {permissions.includes(P.AIRPORT_LOUNGE_BOOKING_CHECK_IN) && (
              <LoadingButton
                className="px-12 max-md:w-full max-md:rounded-full"
                color={isCheckin ? "success" : "primary"}
                loading={checkinBookingMutation.isPending}
                onClick={handleCheckin}
                variant="contained"
              >
                {isCheckin ? "ĐÃ CHECK IN" : "CHECK IN"}
              </LoadingButton>
            )}
            <AirportLoungeBookingCheckinDialog onClose={() => onToggle(false)} open={isOpen} />
          </Stack>
        </Stack>
      </Flex>

      <Card className="mt-6 md:max-w-lg">
        <CardHeader title="Thông tin thêm" />
        <CardContent>
          <Text className="mb-1 text-lg font-bold">Ảnh chụp check-in</Text>
          {booking?.checkinPhoto ? (
            <img className="max-h-80 max-w-80" src={booking.checkinPhoto} />
          ) : (
            <Text className="text-gray-600">Không có dữ liệu</Text>
          )}
          {permissions.includes(P.AIRPORT_LOUNGE_BOOKING_CHECK_IN) && (
            <Flex marginTop={2}>
              <LoadingButton
                className="px-6"
                loading={checkinEvidentMutation.isPending}
                onClick={() => inputRef.current?.click()}
                startIcon={<FileUploadOutlined />}
                variant="outlined"
              >
                Cập nhật ảnh
              </LoadingButton>
              <input accept="image/*" hidden onChange={handleFileChange} ref={inputRef} type="file" />
            </Flex>
          )}
        </CardContent>
      </Card>
    </Container>
  )
}

export default AirportLoungeBookingView
