import { Autocomplete, TextField } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Text } from "components/common"
import { Controller, useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { profileSelector } from "reducers/profileSlice"
import { agencyService } from "services"

const AgencyForm = () => {
  const { isTypeSystem } = useSelector(profileSelector)
  const { control } = useFormContext<AirportLoungeBookingCreateBody>()

  const { data: agencyQuery } = useQuery({
    queryFn: () => agencyService.fetchAgencies(),
    queryKey: ["agencyService.fetchAgencies"],
  })
  const { items: agencies = [] } = agencyQuery ?? {}

  return (
    <Controller
      control={control}
      name="agencyId"
      render={({ field: { onChange, value: agencyId }, fieldState: { error } }) => {
        const selectedAgency = agencies.find((agency) => agency.id === agencyId)
        return (
          <Autocomplete<Agency>
            filterOptions={(options, state) =>
              options.filter(
                (option) =>
                  option.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option.code.toLowerCase().includes(state.inputValue.toLowerCase()),
              )
            }
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, agency) => {
              onChange(agency?.id)
            }}
            options={agencies}
            readOnly={!isTypeSystem}
            renderInput={(params) => (
              <TextField
                label="Đại lý"
                required
                {...params}
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
              />
            )}
            renderOption={(props, option) => (
              <Text {...props}>
                <span className="mr-0.5 text-gray-600">{option.code}</span>
                {" - "}
                {option.name}
              </Text>
            )}
            sx={isTypeSystem ? {} : { display: "none" }}
            value={selectedAgency ?? null}
          />
        )
      }}
      rules={{
        required: "Đại lý không được để trống",
      }}
    />
  )
}

export default AgencyForm
