import { DeleteOutlined, InfoOutlined } from "@mui/icons-material"
import { Button, Chip, Stack, Tooltip, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query"
import { Flex, Text } from "components/common"
import { useConfirmationDialog, useSearch } from "hooks"
import { P } from "models/Permissions"
import { enqueueSnackbar } from "notistack"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { profileSelector } from "reducers/profileSlice"
import { privateRoute } from "routes"
import { airportLoungeBookingService, queryClient } from "services"
import { formatDateTime } from "utils/common"

import { AirportLoungeBookingSearch } from "."

const AirportLoungeBookingList = () => {
  const { openConfirm } = useConfirmationDialog()
  const { isTypeSystem, permissions = [] } = useSelector(profileSelector)
  const { dataSearch, onSearchChange } = useSearch()

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => airportLoungeBookingService.fetchBookings(dataSearch),
    queryKey: ["airportLoungeBookingService.fetchBookings", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  const archiveBookingMutation = useMutation({
    mutationFn: airportLoungeBookingService.archiveBooking,
    onSuccess: () => {
      enqueueSnackbar("Xoá booking thành công")
      queryClient.invalidateQueries({ queryKey: ["airportLoungeBookingService.fetchBookings"] })
    },
  })

  return (
    <Stack>
      <Flex className="justify-between">
        <Typography variant="h4">Danh sách vé</Typography>
        {permissions.includes(P.AIRPORT_LOUNGE_BOOKING_CREATE) && (
          <Link to={privateRoute.airportLoungeFormBookingsCreate.path}>
            <Button variant="contained">Đặt vé phòng chờ</Button>
          </Link>
        )}
      </Flex>
      <AirportLoungeBookingSearch className="max-md:hidden" marginTop={3} onSearchChange={onSearchChange} />

      <DataGrid
        columns={([] as GridColDef<AirportLoungeBooking>[])
          .concat([
            {
              field: "bookingCode",
              flex: 1,
              headerName: "Mã đặt vé",
              minWidth: 140,
              renderCell: ({ row: item }) => (
                <Link to={privateRoute.airportLoungeBookingsView.url(item)}>
                  <Text color="primary" fontWeight="bold">
                    {item.bookingCode}
                  </Text>
                </Link>
              ),
              sortable: false,
            },
          ])
          .concat(
            isTypeSystem
              ? [
                  {
                    field: "agency",
                    flex: 1,
                    headerName: "Đại lý",
                    minWidth: 180,
                    renderCell: ({ row: item }) => item.agency.name,
                    sortable: false,
                  },
                ]
              : [],
          )
          .concat([
            {
              field: "contactName",
              flex: 1,
              headerName: "Khách hàng",
              minWidth: 180,
              renderCell: ({ row: item }) => item.contactName,
              sortable: false,
            },
            {
              field: "createdAt",
              flex: 1,
              headerName: "Ngày đặt",
              minWidth: 140,
              renderCell: ({ row: item }) => formatDateTime(item.createdAt),
            },
            {
              field: "checkinAt",
              flex: 1,
              headerName: "Check-in",
              minWidth: 120,
              renderCell: ({ row: item }) => formatDateTime(item.checkinAt),
            },
            {
              field: "airport",
              flex: 1,
              headerName: "Sân bay",
              minWidth: 180,
              renderCell: ({ row: item }) => item.airport.name,
              sortable: false,
            },
            {
              field: "airportLounge",
              flex: 1,
              headerName: "Phòng chờ",
              minWidth: 180,
              renderCell: ({ row: item }) => item.airportLounge.name,
              sortable: false,
            },
            {
              field: "servingCount",
              flex: 1,
              headerName: "Số vé",
              minWidth: 120,
              renderCell: ({ row: item }) => (
                <Tooltip
                  title={
                    <Stack>
                      {item.bookingFares.map((bookingFare) => (
                        <Flex gap={3} justifyContent="space-between" key={bookingFare.id}>
                          <Typography>{bookingFare.airportLoungeFare.name}</Typography>
                          <Typography>x{bookingFare.count}</Typography>
                        </Flex>
                      ))}
                    </Stack>
                  }
                >
                  <Typography>{item.servingCount}</Typography>
                </Tooltip>
              ),
              sortable: false,
            },
            {
              field: "status",
              flex: 1,
              headerName: "Trạng thái",
              minWidth: 160,
              renderCell: ({ row: item }) => {
                if (item.status === "HOLD") return <Chip label="Giữ chỗ" />
                if (item.status === "CHECKIN") return <Chip color="warning" label="Đã check-in" />
                if (item.status === "PAID") return <Chip color="success" label="Đã thanh toán" />
              },
              sortable: false,
            },
            {
              align: "right",
              field: "actions",
              headerName: "",
              renderCell: ({ row: item }) => {
                return (
                  <Flex className="flex-nowrap gap-2">
                    <Link to={privateRoute.airportLoungeBookingsView.url(item)}>
                      <Button color="inherit" size="small">
                        <InfoOutlined />
                      </Button>
                    </Link>
                    {permissions.includes(P.AIRPORT_LOUNGE_BOOKING_DELETE) && (
                      <Button
                        color="error"
                        onClick={() => {
                          openConfirm({
                            onConfirmAsync: () => archiveBookingMutation.mutateAsync({ archived: true, id: item.id }),
                          })
                        }}
                        size="small"
                      >
                        <DeleteOutlined />
                      </Button>
                    )}
                  </Flex>
                )
              },
              sortable: false,
              width: 128,
            },
          ])}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />
    </Stack>
  )
}

export default AirportLoungeBookingList
