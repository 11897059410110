import { LoadingButton } from "@mui/lab"
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton, Flex } from "components/common"
import { DateTime } from "luxon"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { queryClient, specialService } from "services"
import { formatDateTime, formatRemoveAccents, formatTextAndNumberAndUpperCase } from "utils/common"
import { isValidEmail, isValidPhone } from "utils/validator"
import { getChipLabelByStatus } from "views/AirportLoungeBookingCreate/utils"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  InsuranceItem?: SpecialService
}

const CreateCoverNoteDialog = ({ InsuranceItem, onClose, onSuccess, ...props }: Props) => {
  const isEdit = !!InsuranceItem?.passport
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    resetField,
  } = useForm<InsuranceCoverNoteCreateBody>()

  useEffect(() => {
    reset({
      birthDay: InsuranceItem?.birthDay ? (DateTime.fromISO(InsuranceItem.birthDay) as any) : null,
      emailAddress: InsuranceItem?.email ?? "",
      passengerName: InsuranceItem?.passengerName ?? "",
      passport: InsuranceItem?.passport ?? "",
      phoneNumber: InsuranceItem?.phoneNumber ?? "",
    })

    if (!isEdit) resetField("birthDay")
  }, [isEdit, resetField, reset, props.open, InsuranceItem])

  const createCoverNoteMutation = useMutation({ mutationFn: specialService.createCoverNoteService })
  const updateCoverNoteMutation = useMutation({ mutationFn: specialService.updateCoverNoteService })

  const onSubmitCreate = async (values: InsuranceCoverNoteCreateBody) => {
    const payloadCreate: InsuranceCoverNoteCreateBody = {
      ...values,
      agencyCode: InsuranceItem?.agencyCode ?? null,
      agencyEmail: InsuranceItem?.agencyEmail ?? null,
      agencyName: InsuranceItem?.agencyName ?? null,
      flightJourney: InsuranceItem?.flightJourney ?? [],
      insuranceId: InsuranceItem?.id,
      passengerId: InsuranceItem?.passengerId,
      planDetails: InsuranceItem?.planDetails,
      planId: InsuranceItem?.planId,
      planName: InsuranceItem?.planName,
      type: "INSURANCE",
    }
    await createCoverNoteMutation.mutateAsync({ ...payloadCreate })
    enqueueSnackbar("Tạo cover note thành công")
    queryClient.invalidateQueries({ queryKey: ["specialService.fetchServices"] })
    onClose()
    onSuccess?.()
  }

  const onSubmitEdit = async (values: InsuranceCoverNoteEditBody) => {
    const payloadEdit: InsuranceCoverNoteEditBody = {
      ...values,
      insuranceId: InsuranceItem?.id,
    }
    await updateCoverNoteMutation.mutateAsync({ ...payloadEdit })
    enqueueSnackbar("Chỉnh sửa cover note thành công")
    queryClient.invalidateQueries({ queryKey: ["specialService.fetchServices"] })
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{isEdit ? "Chỉnh sửa cover note" : "Tạo cover note"}</DialogTitle>
      <DialogContent>
        <Card className="mb-4">
          <CardHeader title="Thông tin gói bảo hiểm" />
          <CardContent>
            <Stack spacing={2}>
              <Flex className="justify-between">
                <Typography variant="h6">Tên gói:</Typography>
                <Typography variant="h6">{InsuranceItem?.planName}</Typography>
              </Flex>
              <Flex className="justify-between">
                <Typography variant="h6">Trạng thái:</Typography>
                <Typography variant="h6">{getChipLabelByStatus(InsuranceItem?.status!)}</Typography>
              </Flex>
              <Flex className="justify-between">
                <Typography variant="h6">Hành trình:</Typography>
                <Typography variant="h6">
                  {InsuranceItem?.flightJourney?.map((trip) => `${trip.from}-${trip.to}`).join(", ")}
                </Typography>
              </Flex>
              <Flex className="justify-between">
                <Typography variant="h6">Thời gian bắt đầu:</Typography>
                <Typography variant="h6">
                  {formatDateTime(InsuranceItem?.flightJourney?.[0].boardingTime ?? "")}
                </Typography>
              </Flex>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title={`Khách hàng ${InsuranceItem?.passengerName}`} />
          <CardContent>
            <Grid columnSpacing={6} container rowSpacing={4}>
              <Grid item md={6} xs={12}>
                <Controller
                  control={control}
                  name="passengerName"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      label="Tên khách hàng"
                      required
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      onBlur={(event) => {
                        const trimmedName = formatRemoveAccents(event.target.value).trim()
                        field.onChange(trimmedName)
                      }}
                      onChange={(event) => {
                        const upperName = event.target.value.toUpperCase()
                        field.onChange(upperName)
                      }}
                    />
                  )}
                  rules={{
                    required: "Tên khách hàng không được để trống",
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      label="Số điện thoại liên hệ"
                      required
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      onBlur={(event) => {
                        const trimmedName = event.target.value.trim()
                        field.onChange(trimmedName)
                      }}
                    />
                  )}
                  rules={{
                    required: "Số điện thoại không được để trống",
                    validate: {
                      isValid: (value) => isValidPhone(value) || "Số điện thoại không hợp lệ",
                    },
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  control={control}
                  name="emailAddress"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      label="Email liên hệ"
                      required
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      onBlur={(event) => {
                        const trimmedName = event.target.value.trim()
                        field.onChange(trimmedName)
                      }}
                    />
                  )}
                  rules={{
                    required: "Email không được để trống",
                    validate: {
                      isValid: (value) => isValidEmail(value) || "Email không hợp lệ",
                    },
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  control={control}
                  name="birthDay"
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      className="w-full"
                      format="dd/MM/yyyy"
                      label="Ngày sinh"
                      {...field}
                      slotProps={{ textField: { error: !!error, helperText: error?.message, required: true } }}
                    />
                  )}
                  rules={{
                    required: "Ngày sinh không được để trống",
                    validate: (value) => {
                      const selectedDate = DateTime.fromISO(value as unknown as string)
                      const now = DateTime.now()
                      const minDate = now.minus({ years: 75 })
                      const maxDate = now.minus({ days: 1 })
                      if (!selectedDate.isValid) {
                        return "Ngày sinh không hợp lệ"
                      }
                      if (selectedDate < minDate || selectedDate > maxDate) {
                        return "Ngày sinh bắt buộc trong khoảng từ 1 ngày tuổi đến 75 tuổi"
                      }
                      return true
                    },
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  control={control}
                  name="passport"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      label="Số hộ chiếu"
                      required
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      onBlur={(event) => {
                        const trimmed = event.target.value.trim()
                        field.onChange(trimmed)
                      }}
                      onChange={(event) => {
                        const formatted = formatTextAndNumberAndUpperCase(event.target.value)
                        field.onChange(formatted)
                      }}
                    />
                  )}
                  rules={{
                    maxLength: {
                      message: "Passport tối đa 20 ký tự",
                      value: 20,
                    },
                    minLength: {
                      message: "Passport tối thiểu 8 ký tự",
                      value: 8,
                    },
                    required: "Passport không được để trống",
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <LoadingButton
          loading={isSubmitting}
          onClick={handleSubmit(isEdit ? onSubmitEdit : onSubmitCreate)}
          variant="contained"
        >
          {isEdit ? "Chỉnh sửa cover note" : "Tạo cover note"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateCoverNoteDialog
