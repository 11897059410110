import { client } from "./axios"

const createLounge = (body: AirportLoungeCreateBody): Promise<AirportLounge> => client.post(`/airport_lounges`, body)
const deleteLounge = ({ id }: ByID): Promise<null> => client.delete(`/airport_lounges/${id}`)
const fetchLounges = (params?: PaginateParams): Promise<PaginateResponse<AirportLounge>> =>
  client.get(`/airport_lounges`, { params })
const getLounge = ({ id }: ByID): Promise<AirportLounge> => client.get(`/airport_lounges/${id}`)
const updateLounge = ({ id, ...body }: AirportLoungeUpdateBody): Promise<AirportLounge> =>
  client.patch(`/airport_lounges/${id}`, body)

const airportLoungeService = {
  createLounge,
  deleteLounge,
  fetchLounges,
  getLounge,
  updateLounge,
}

export default airportLoungeService
