import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Stack, TextField } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { airportService, queryClient } from "services"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  airport?: Airport
}

const AirportDialog = ({ airport, onClose, onSuccess, ...props }: Props) => {
  const isCreate = !airport
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<AirportCreateBody>()

  useEffect(() => {
    reset({
      name: airport?.name ?? "",
    })
  }, [reset, props.open, airport])

  const updateAirportMutation = useMutation({ mutationFn: airportService.updateAirport })
  const createAirportMutation = useMutation({ mutationFn: airportService.createAirport })

  const onSubmit = async (values: AirportCreateBody) => {
    if (isCreate) {
      await createAirportMutation.mutateAsync({ ...values })
      enqueueSnackbar("Tạo mới sân bay thành công")
    } else {
      await updateAirportMutation.mutateAsync({ ...values, id: airport.id })
      enqueueSnackbar("Cập nhật sân bay thành công")
    }
    queryClient.invalidateQueries({ queryKey: ["airportService.fetchAirports"] })
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="xs" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{isCreate ? "Tạo sân bay" : "Cập nhật sân bay"}</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Tên sân bay"
                required
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: "Tên sân bay không được để trống",
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
          {isCreate ? "Tạo mới" : "Cập nhật"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AirportDialog
