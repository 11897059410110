import { KeySharp } from "@mui/icons-material"
import { Avatar, Button, Container } from "@mui/material"
import { Center } from "components/common"
import { useSelector } from "react-redux"
import { useToggle } from "react-use"
import { profileSelector } from "reducers/profileSlice"

import { ProfileChangePasswordDialog } from "."

const Profile = () => {
  const { user } = useSelector(profileSelector)
  const [isOpen, onToggle] = useToggle(false)

  return (
    <Container>
      <Center>
        <Avatar className="mr-2 h-7 w-7 bg-primary-main">{user?.username?.[0].toUpperCase()}</Avatar>
        {user?.username}
      </Center>

      <Center className="mt-8">
        <Button
          onClick={() => {
            onToggle(true)
          }}
          startIcon={<KeySharp />}
        >
          Đổi mật khẩu
        </Button>
      </Center>
      <ProfileChangePasswordDialog onClose={() => onToggle(false)} open={isOpen} />
    </Container>
  )
}

export default Profile
