import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist"
import { default as storage } from "redux-persist/lib/storage"

import { profileSlice } from "./profileSlice"
import { themeSlice } from "./themeSlice"

const rootReducer = combineReducers({
  [profileSlice.name]: profileSlice.reducer,
  [themeSlice.name]: themeSlice.reducer,
})

const persistedReducer = persistReducer({ key: "root", storage }, rootReducer)

export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
  },
  reducer: persistedReducer,
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
