import { BoxProps, Grid, MenuItem } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { Flex, SelectClearable } from "components/common"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useDebounce } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { agencyService } from "services"

type FormValues = {
  agencyId: number | string | undefined
  searchText: string | undefined
}

type Props = BoxProps & {
  onSearchChange?: (values: FormValues) => void
}

const AgencyLoungeConfigSearch = ({ onSearchChange, ...props }: Props) => {
  const { control, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      agencyId: "",
      searchText: "",
    },
  })
  const { agencyId } = watch()
  const { searchText } = watch()
  const { isTypeAgency, isTypeSystem, user } = useSelector(profileSelector)

  const { data: agencyQuery } = useQuery({
    queryFn: () => agencyService.fetchAgencies(),
    queryKey: ["agencyService.fetchAgencies"],
  })
  const { items: agencies = [] } = agencyQuery ?? {}

  useDebounce(() => onSearchChange?.({ agencyId: agencyId || undefined, searchText: searchText || undefined }), 100, [
    searchText,
    agencyId,
  ])

  useEffect(() => {
    if (isTypeAgency) {
      setValue("agencyId", user?.agency.id)
    }
  }, [user, setValue, isTypeAgency])

  return (
    <Flex gap={6} {...props}>
      <Grid columnSpacing={4} container rowSpacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="agencyId"
            render={({ field }) => (
              <SelectClearable
                fullWidth
                label="Đại lý"
                select
                size="small"
                {...field}
                clearable={isTypeSystem}
                InputProps={{ readOnly: !isTypeSystem }}
                onClear={() => setValue("agencyId", "")}
              >
                {agencies.map((agency) => (
                  <MenuItem key={agency.id} value={agency.id}>
                    {agency.name}
                  </MenuItem>
                ))}
              </SelectClearable>
            )}
          />
        </Grid>
      </Grid>
    </Flex>
  )
}

export default AgencyLoungeConfigSearch
