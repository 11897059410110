import { Add, InfoOutlined } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { Flex } from "components/common"
import { useSearch } from "hooks"
import { P } from "models/Permissions"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useToggle } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { agencyService } from "services"

import { AgencyDialog, AgencySearch } from "."

const AgencyList = () => {
  const { permissions = [] } = useSelector(profileSelector)
  const { dataSearch, onSearchChange } = useSearch({ sortBy: "name", sortDirection: "asc" })

  const [isOpen, onToggle] = useToggle(false)
  const [agency, setAgency] = useState<Agency>()

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => agencyService.fetchAgencies(dataSearch),
    queryKey: ["agencyService.fetchAgencies", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  return (
    <Stack>
      <Flex className="justify-between">
        <Typography variant="h4">Đại lý</Typography>
        {permissions.includes(P.AGENCY_CREATE) && (
          <Button
            onClick={() => {
              setAgency(undefined)
              onToggle(true)
            }}
            startIcon={<Add />}
          >
            Tạo mới
          </Button>
        )}
      </Flex>
      <AgencySearch marginTop={3} onSearchChange={onSearchChange} />

      <DataGrid
        columns={[
          { field: "name", flex: 1, headerName: "Tên đại lý", sortable: true },
          { field: "code", flex: 1, headerName: "Mã đại lý", sortable: true },
          {
            align: "right",
            field: "actions",
            headerName: "",
            renderCell: ({ row: item }) => {
              return (
                <Flex className="flex-nowrap gap-2">
                  {permissions.includes(P.AGENCY_FETCH) && (
                    <Button
                      color="inherit"
                      onClick={() => {
                        setAgency(item)
                        onToggle(true)
                      }}
                      size="small"
                    >
                      <InfoOutlined />
                    </Button>
                  )}
                </Flex>
              )
            },
            sortable: false,
            width: 182 - 52,
          },
        ]}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />

      <AgencyDialog agency={agency} onClose={() => onToggle(false)} open={isOpen} />
    </Stack>
  )
}

export default AgencyList
