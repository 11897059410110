import { Home } from "@mui/icons-material"
import { Breadcrumbs, IconButton, Typography } from "@mui/material"
import { useLocation } from "react-router-dom"
import { privateRoute } from "routes"

const AppBreadcrumb = () => {
  const location = useLocation()

  const routes = (location.pathname.match(/\/[\w-]*/g) ?? [])
    .map((_, index, array) => array.slice(0, index + 1).join(""))
    .map((item) => Object.values(privateRoute).find((route) => item === route.path))
    .filter((item) => item?.name)

  return (
    <Breadcrumbs>
      <IconButton disabled>
        <Home color="primary" />
      </IconButton>
      {routes.map((item, index) => (
        <Typography color="primary" key={index} variant="h5">
          {item?.name}
        </Typography>
      ))}
    </Breadcrumbs>
  )
}

export default AppBreadcrumb
