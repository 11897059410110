import { LoginScreen } from "views/Auth"

const authRoute = {
  login: {
    component: LoginScreen,
    path: "/login",
    url: "/auth/login",
  },
}

export default authRoute
