import { Construction, DeleteOutlined } from "@mui/icons-material"
import { Box, Button, Stack, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query"
import { Flex } from "components/common"
import { useConfirmationDialog, useSearch } from "hooks"
import { P } from "models/Permissions"
import { enqueueSnackbar } from "notistack"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { profileSelector } from "reducers/profileSlice"
import { privateRoute } from "routes"
import { agencyLoungeConfigService, queryClient } from "services"
import { formatCurrency } from "utils/common"

import AgencyLoungeConfigSearch from "./AgencyLoungeConfigSearch"

const AgencyLoungeConfigList = () => {
  const { openConfirm } = useConfirmationDialog()
  const { dataSearch, onSearchChange } = useSearch()
  const { isTypeSystem, permissions = [] } = useSelector(profileSelector)

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => agencyLoungeConfigService.fetchAgencyLoungeConfigs(dataSearch),
    queryKey: ["agencyLoungeConfigService.fetchAgencyLoungeConfigs", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  const deleteAgencyLoungeConfigMutation = useMutation({
    mutationFn: agencyLoungeConfigService.deleteAgencyLoungeConfig,
    onSuccess: () => {
      enqueueSnackbar("Xoá cài đặt phí thành công")
      queryClient.invalidateQueries({ queryKey: ["agencyLoungeConfigService.fetchAgencyLoungeConfigs"] })
      queryClient.invalidateQueries({ queryKey: ["airportService.fetchAirportsAndFares"] })
    },
  })

  return (
    <Box>
      <Flex className="justify-between">
        <Typography variant="h4">Cài đặt giá theo đại lý</Typography>
        {permissions.includes(P.LOUNGE_FARE_CONFIG_CREATE) && (
          <Link to={privateRoute.settingsAgencyLoungeConfigsCreate.path}>
            <Button startIcon={<Construction />}>Cài đặt giá</Button>
          </Link>
        )}
      </Flex>
      <AgencyLoungeConfigSearch marginTop={3} onSearchChange={onSearchChange}></AgencyLoungeConfigSearch>

      <DataGrid
        columns={([] as GridColDef<AgencyLoungeConfig>[])
          .concat([
            {
              field: "airportLoungeFare.airportLounge.name",
              flex: 1,
              headerName: "Phòng chờ",
              renderCell: ({ row }) => (
                <Stack component="li" style={{ alignItems: "flex-start" }}>
                  <Box>{row.airportLoungeFare.airportLounge.name}</Box>
                  <Box className="text-sm text-gray-600">
                    {row.airportLoungeFare.airportLounge.airport.name} -{" "}
                    {row.airportLoungeFare.airportLounge.isDomestic ? "Nội địa" : "Quốc tế"}
                  </Box>
                </Stack>
              ),
              sortable: false,
            },
            {
              field: "airportLoungeFare.name",
              flex: 1,
              headerName: "Loại vé",
              renderCell: ({ row }) => row.airportLoungeFare.name,
              sortable: false,
            },
          ])
          .concat(
            isTypeSystem
              ? [
                  {
                    field: "agency.name",
                    flex: 1,
                    headerName: "Đại lý",
                    renderCell: ({ row }) => row.agency.name,
                    sortable: false,
                  },
                ]
              : [],
          )
          .concat([
            {
              field: "airportLoungeFarePrice",
              flex: 1,
              headerName: "Giá tiền",
              renderCell: ({ row }) => formatCurrency(row.price),
              sortable: false,
            },
            {
              align: "center",
              field: "actions",
              headerName: "",
              renderCell: ({ row: item }) => {
                return (
                  <Flex className="flex-nowrap gap-2">
                    {permissions.includes(P.LOUNGE_FARE_CONFIG_DELETE) && (
                      <Button
                        color="error"
                        onClick={() => {
                          openConfirm({
                            onConfirmAsync: () => deleteAgencyLoungeConfigMutation.mutateAsync({ id: item.id }),
                          })
                        }}
                        size="small"
                      >
                        <DeleteOutlined />
                      </Button>
                    )}
                  </Flex>
                )
              },
              sortable: false,
              width: 128 - 52,
            },
          ])}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />
    </Box>
  )
}

export default AgencyLoungeConfigList
