import { AddOutlined, DeleteOutlined } from "@mui/icons-material"
import { Button, Grid, TextField } from "@mui/material"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { formatRemoveAccents } from "utils/common"

const FlightForm = () => {
  const { control } = useFormContext<InsuranceServiceDraft>()
  const { append, fields, remove } = useFieldArray({ control, name: "passengers" })

  return (
    <>
      {fields.map((field, index) => (
        <Grid columnSpacing={2} container key={field.id} mb={2} rowSpacing={2}>
          <Grid item md={8} xs={10}>
            <Controller
              control={control}
              name={`passengers.${index}.name`}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label={`Họ và tên khách hàng ${fields.length > 1 ? index + 1 : ""}`}
                  required
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={(event) => {
                    const trimmedName = formatRemoveAccents(event.target.value).trim()
                    field.onChange(trimmedName)
                  }}
                  onChange={(event) => {
                    const formattedTrip = event.target.value.toUpperCase()
                    field.onChange(formattedTrip)
                  }}
                />
              )}
              rules={{
                required: "Tên khách hàng không được để trống",
              }}
            />
          </Grid>

          <Grid className="flex items-center" item md={4} xs={2}>
            {fields.length > 1 && (
              <Button className="mr-2" color="error" onClick={() => remove(index)} variant="contained">
                <DeleteOutlined />
              </Button>
            )}
            {index === fields.length - 1 && (
              <Button onClick={() => append({ name: "" })} variant="contained">
                <AddOutlined />
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default FlightForm
