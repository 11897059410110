import { Box, Stack, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import { Flex, Text } from "components/common"
import { useSearch } from "hooks"
import { auditLogService } from "services"
import { formatDateTime } from "utils/common"

const AuditLogList = () => {
  const { dataSearch, onSearchChange } = useSearch()

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => auditLogService.fetchAuditLogs(dataSearch),
    queryKey: ["auditLogService.fetchAuditLogs", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  return (
    <Stack>
      <Flex className="justify-between">
        <Typography variant="h4">Audit Logs</Typography>
      </Flex>

      <DataGrid
        columns={[
          {
            field: "action",
            headerName: "Thao tác",
            minWidth: 320,
            renderCell: ({ row: item }) => (
              <Stack>
                <Text className="font-bold">{item.action}</Text>
                <Text className="font-bold">{item.feature}</Text>
                <Text className="font-bold text-[blue]">{item.refResource}</Text>
                <Box marginTop={1}>{formatDateTime(item.createdAt)}</Box>
              </Stack>
            ),
            sortable: false,
          },
          {
            field: "newValue",
            flex: 1,
            headerName: "Giá trị mới",
            renderCell: ({ row: item }) =>
              item.newValue ? (
                <Stack alignItems="flex-start">
                  {Object.entries(JSON.parse(item.newValue)).map(([key, value], index) => (
                    <Flex className="items-center gap-1" key={index}>
                      <Box>{key}: </Box>
                      <Box className="text-sm font-semibold">{String(value)}</Box>
                    </Flex>
                  ))}
                </Stack>
              ) : (
                <Box className="text-gray-600">Empty</Box>
              ),
            sortable: false,
          },
        ]}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />
    </Stack>
  )
}

export default AuditLogList
