import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Stack } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { DialogCloseButton, InputPassword } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { userService } from "services"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  user?: User
}

const UserResetPasswordDialog = ({ onClose, onSuccess, user, ...props }: Props) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<ResetPasswordBody>({})

  const resetPasswordMutation = useMutation({ mutationFn: userService.resetPasswordUser })

  const onSubmit = async (values: ResetPasswordBody) => {
    if (values.newPassword !== values.confirmNewPassword) {
      enqueueSnackbar("Mật khẩu mới và xác nhận không khớp", { variant: "error" })
      return
    }

    const { confirmNewPassword, ...payload } = values
    await resetPasswordMutation.mutateAsync(payload)
    enqueueSnackbar("Đặt lại mật khẩu thành công", { variant: "success" })
    onClose()
    onSuccess?.()
  }

  useEffect(() => {
    if (props.open) {
      reset({
        confirmNewPassword: "",
        id: user?.id ?? "",
        newPassword: "",
        oldPassword: "",
      })
    }
  }, [reset, props.open, user])

  return (
    <Dialog maxWidth="xs" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Đặt lại mật khẩu</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Controller
            control={control}
            name="newPassword"
            render={({ field, fieldState: { error } }) => (
              <InputPassword
                fullWidth
                label="Mật khẩu mới"
                required
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              maxLength: 20,
              minLength: 6,
              required: "Mật khẩu mới không được để trống",
            }}
          />
          <Controller
            control={control}
            name="confirmNewPassword"
            render={({ field, fieldState: { error } }) => (
              <InputPassword
                fullWidth
                label="Xác nhận mật khẩu mới"
                required
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: "Xác nhận mật khẩu mới không được để trống",
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
          Đặt lại mật khẩu
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default UserResetPasswordDialog
