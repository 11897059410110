import { client } from "./axios"

const createBooking = (body: AirportLoungeBookingCreateBody): Promise<AirportLoungeBooking> =>
  client.post(`/airport_lounge_bookings`, body)
const archiveBooking = ({ id, ...body }: AirportLoungeBookingArchiveBody): Promise<AirportLoungeBooking> =>
  client.patch(`/airport_lounge_bookings/${id}/archive`, body)
const fetchBookings = (params?: PaginateParams): Promise<PaginateResponse<AirportLoungeBooking>> =>
  client.get(`/airport_lounge_bookings`, { params })
const getBooking = ({ id }: ByID): Promise<AirportLoungeBooking> => client.get(`/airport_lounge_bookings/${id}`)
const updateBooking = ({ id, ...body }: AirportLoungeBookingUpdateBody): Promise<AirportLoungeBooking> =>
  client.patch(`/airport_lounge_bookings/${id}`, body)

const exportBooking = (body: BookingSearchParams): Promise<Blob> =>
  client.post(`/airport_lounge_bookings/export`, body, { responseType: "blob" })
const checkinBooking = ({ id }: ByID): Promise<AirportLoungeBooking> =>
  client.post(`/airport_lounge_bookings/${id}/check-in`)
const checkinBookingEvident = ({ formData, id }: ByID & { formData: FormData }): Promise<AirportLoungeBooking> =>
  client.post(`/airport_lounge_bookings/${id}/check-in-evident`, formData)

const airportLoungeBookingService = {
  archiveBooking,
  checkinBooking,
  checkinBookingEvident,
  createBooking,
  exportBooking,
  fetchBookings,
  getBooking,
  updateBooking,
}

export default airportLoungeBookingService
