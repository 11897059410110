import { Search } from "@mui/icons-material"
import { BoxProps, Grid, InputAdornment, TextField } from "@mui/material"
import { Flex } from "components/common"
import { Controller, useForm } from "react-hook-form"
import { useDebounce } from "react-use"

type FormValues = {
  searchText: string
}

type Props = BoxProps & {
  onSearchChange?: (values: FormValues) => void
}

const AgencySearch = ({ onSearchChange, ...props }: Props) => {
  const { control, watch } = useForm<FormValues>({
    defaultValues: {
      searchText: "",
    },
  })

  const { searchText } = watch()
  useDebounce(() => onSearchChange?.({ searchText }), 100, [searchText])

  return (
    <Flex gap={6} {...props}>
      <Grid columnSpacing={4} container rowSpacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Controller
            control={control}
            name="searchText"
            render={({ field }) => (
              <TextField
                fullWidth
                placeholder="Tìm kiếm..."
                size="small"
                {...field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Flex>
  )
}

export default AgencySearch
