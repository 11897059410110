import { Add, DeleteOutlined, Edit } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query"
import { Flex } from "components/common"
import { useSearch } from "hooks"
import { useConfirmationDialog } from "hooks/useConfirmationDialog"
import { P } from "models/Permissions"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useToggle } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { airportService, queryClient } from "services"

import { AirportDialog, AirportSearch } from "."

const AirportList = () => {
  const { openConfirm } = useConfirmationDialog()
  const { permissions = [] } = useSelector(profileSelector)
  const { dataSearch, onSearchChange } = useSearch()

  const [isOpen, onToggle] = useToggle(false)
  const [airport, setAirport] = useState<Airport>()

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => airportService.fetchAirports(dataSearch),
    queryKey: ["airportService.fetchAirports", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  const deleteAirportMutation = useMutation({
    mutationFn: airportService.deleteAirport,
    onSuccess: () => {
      enqueueSnackbar("Xoá sân bay thành công")
      queryClient.invalidateQueries({ queryKey: ["airportService.fetchAirports"] })
    },
  })

  return (
    <Stack>
      <Flex className="justify-between">
        <Typography variant="h4">Sân bay</Typography>
        {permissions.includes(P.AIRPORT_CREATE) && (
          <Button
            onClick={() => {
              setAirport(undefined)
              onToggle(true)
            }}
            startIcon={<Add />}
          >
            Tạo mới
          </Button>
        )}
      </Flex>
      <AirportSearch marginTop={3} onSearchChange={onSearchChange} />

      <DataGrid
        columns={[
          { field: "name", flex: 1, headerName: "Tên sân bay", sortable: true },
          {
            field: "airportTerminals",
            flex: 2,
            headerName: "Phòng chờ",
            renderCell: ({ row: item }) => {
              return (
                <Stack>
                  {item.airportLounges?.map((lounge) => (
                    <Typography key={lounge.id} variant="body2">
                      {lounge.name}
                    </Typography>
                  ))}
                </Stack>
              )
            },
            sortable: false,
          },
          {
            align: "right",
            field: "actions",
            headerName: "",
            renderCell: ({ row: item }) => {
              return (
                <Flex className="flex-nowrap gap-2">
                  {permissions.includes(P.AIRPORT_UPDATE) && (
                    <Button
                      onClick={() => {
                        setAirport(item)
                        onToggle(true)
                      }}
                      size="small"
                    >
                      <Edit />
                    </Button>
                  )}
                  {permissions.includes(P.AIRPORT_DELETE) && (
                    <Button
                      color="error"
                      onClick={() => {
                        openConfirm({
                          onConfirmAsync: () => deleteAirportMutation.mutateAsync({ id: item.id }),
                        })
                      }}
                      size="small"
                    >
                      <DeleteOutlined />
                    </Button>
                  )}
                </Flex>
              )
            },
            sortable: false,
            width: 128,
          },
        ]}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />

      <AirportDialog airport={airport} onClose={() => onToggle(false)} open={isOpen} />
    </Stack>
  )
}

export default AirportList
