import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { InputNumber } from "components"
import { DialogCloseButton, SelectClearable } from "components/common"
import { enqueueSnackbar } from "notistack"
import { useEffect, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import { airportLoungeFareService, airportService, queryClient } from "services"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  airportLoungeFare?: AirportLoungeFare
}

type FormValues = {
  airportId: number | string
  airportLoungeId: number | string
  name: string
  price: number
}

const AirportLoungeFareDialog = ({ airportLoungeFare, onClose, onSuccess, ...props }: Props) => {
  const isCreate = !airportLoungeFare
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<FormValues>({})
  const selectedAirportId = watch("airportId")

  const updateLoungeFareMutation = useMutation({ mutationFn: airportLoungeFareService.updateLoungeFare })
  const createLoungeFareMutation = useMutation({ mutationFn: airportLoungeFareService.createLoungeFare })

  const { data: airportQuery } = useQuery({
    queryFn: () => airportService.fetchAirports(),
    queryKey: ["airportService.fetchAirports"],
  })
  const { items: airports } = airportQuery ?? {}

  const airportLounges = useMemo(() => {
    return (airports ?? []).flatMap((airport) =>
      airport.airportLounges.map((airportLounge) => ({
        ...airportLounge,
        airport,
      })),
    )
  }, [airports])

  useEffect(() => {
    const airportLounge = airportLounges.find(
      (airportLounge) => airportLounge.id === airportLoungeFare?.airportLounge.id,
    )
    reset({
      airportId: airportLounge?.airport.id ?? "",
      airportLoungeId: airportLounge?.id ?? "",
      name: airportLoungeFare?.name ?? "",
      price: airportLoungeFare?.price ?? 0,
    })
  }, [reset, props.open, airportLoungeFare, airportLounges])

  const onSubmit = async (values: FormValues) => {
    if (isCreate) {
      await createLoungeFareMutation.mutateAsync({
        airportLoungeId: values.airportLoungeId,
        name: values.name,
        price: values.price,
      })
      enqueueSnackbar("Tạo mới loại vé thành công")
    } else {
      await updateLoungeFareMutation.mutateAsync({
        airportLoungeId: values.airportLoungeId,
        id: airportLoungeFare.id,
        name: values.name,
        price: values.price,
      })
      enqueueSnackbar("Cập nhật loại vé thành công")
      queryClient.invalidateQueries({ queryKey: ["airportLoungeFareService.getLoungeFare"] })
    }
    queryClient.invalidateQueries({ queryKey: ["airportLoungeFareService.fetchLoungeFare"] })
    onClose()
    onSuccess?.()
  }
  return (
    <Dialog maxWidth="xs" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>{isCreate ? "Tạo loại vé" : "Cập nhật loại vé"}</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Controller
            control={control}
            name="airportId"
            render={({ field, fieldState: { error } }) => {
              return (
                <SelectClearable
                  fullWidth
                  label="Sân bay"
                  required
                  select
                  {...field}
                  clearable={isCreate}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{ readOnly: !isCreate }}
                  onChange={(event) => {
                    field.onChange(event)
                    setValue("airportLoungeId", "")
                  }}
                  onClear={() => {
                    setValue("airportId", "")
                    setValue("airportLoungeId", "")
                  }}
                >
                  {airports?.map((airport) => (
                    <MenuItem key={airport.id} value={airport.id}>
                      {airport.name}
                    </MenuItem>
                  ))}
                </SelectClearable>
              )
            }}
            rules={{
              required: "Sân bay không được để trống",
            }}
          />
          <Controller
            control={control}
            name="airportLoungeId"
            render={({ field: { onChange, value: airportLoungeId }, fieldState: { error } }) => {
              const selectedLounge = airportLounges.find((airportLounge) => airportLounge.id === airportLoungeId)
              return (
                <Autocomplete<AirportLounge>
                  autoHighlight
                  filterOptions={(options, state) =>
                    options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
                  }
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, airportLounge) => {
                    onChange(airportLounge?.id ?? "")
                    setValue("airportId", airportLounge?.airport.id ?? "", {
                      shouldValidate: !!airportLounge?.airport.id,
                    })
                  }}
                  options={airportLounges.filter(
                    (airportLounge) => !selectedAirportId || airportLounge.airport.id === selectedAirportId,
                  )}
                  readOnly={!isCreate}
                  renderInput={(params) => (
                    <TextField
                      label="Phòng chờ"
                      required
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Stack component="li" style={{ alignItems: "flex-start" }} {...props}>
                      <Box>{option.name}</Box>
                      <Box className="text-sm text-gray-600">
                        {option.airport.name} - {option.isDomestic ? "Nội địa" : "Quốc tế"}
                      </Box>
                    </Stack>
                  )}
                  value={selectedLounge ?? null}
                />
              )
            }}
            rules={{
              required: "Phòng chờ không được để trống",
            }}
          />
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Tên loại vé"
                required
                {...field}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: "Tên loại vé không được để trống",
            }}
          />
          <Controller
            control={control}
            name="price"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Giá tiền"
                required
                {...field}
                error={!!error}
                helperText={error?.message}
                InputProps={{ inputComponent: InputNumber }}
              />
            )}
            rules={{
              required: "Giá tiền không được để trống",
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Đóng</Button>
        <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="contained">
          {isCreate ? "Tạo mới" : "Cập nhật"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AirportLoungeFareDialog
