import { LoadingButton } from "@mui/lab"
import { Button, Card, CardContent, CardHeader, Container, Stack } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { Flex } from "components/common"
import { enqueueSnackbar } from "notistack"
import { FormProvider, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useToggle } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { privateRoute } from "routes"
import { queryClient, specialService } from "services"

import { AgencyForm, FlightForm, PassengerForm } from "./components"

const defaultAirport: AirportIata = {
  airportPermalink: null,
  city: null,
  cityEnName: null,
  cityName: null,
  country: null,
  countryEnName: null,
  countryLongCode: null,
  countryName: null,
  displayName: null,
  elevation: 0,
  iata: "",
  icao: "",
  id: 0,
  lat: 0,
  lon: 0,
  name: "",
  nameEN: null,
  popularity: 0,
  regionName: null,
  state: null,
  tz: null,
  worldRegionEnName: null,
  worldRegionName: null,
}

const SpecialServiceCreate = () => {
  const navigate = useNavigate()
  const { user } = useSelector(profileSelector)
  const [isSearch, onToggleSearch] = useToggle(true)

  const form = useForm<InsuranceServiceDraft>({
    defaultValues: {
      agencyEmail: "",
      bookingCode: "",
      coverPrice: 0,
      flightJourney: [
        {
          airportFrom: defaultAirport,
          airportTo: defaultAirport,
          boardingTime: null,
        },
      ],
      passengerId: "",
      passengerName: "",
      passengers: [
        {
          name: "",
        },
      ],
      planDetails: "",
      planId: 0,
      planName: "",
      price: 0,
      priceCommission: 0,
      priceDiscountPercent: 0,
      totalPrice: 0,
    },
  })

  const {
    formState: { defaultValues, isSubmitting },
    handleSubmit,
    reset,
  } = form

  const createInsuranceMutation = useMutation({
    mutationFn: specialService.createDraftService,
    onSuccess: () => {
      enqueueSnackbar("Mua gói bảo hiểm thành công")
      navigate(privateRoute.specialServices.path)
      queryClient.invalidateQueries({ queryKey: ["specialService.fetchServices"] })
    },
  })

  const onSubmit = async (values: InsuranceServiceDraft) => {
    if (!values.planId || !values.planName || !values.planDetails) {
      enqueueSnackbar("Vui lòng chọn một gói bảo hiểm", { variant: "warning" })
      return
    }

    const req: InsuranceDraftCreateBody = {
      insurances: values.passengers.map((passenger) => ({
        ...values,
        agencyCode: user?.agency?.code || "",
        agencyName: user?.agency?.name || "",
        flightJourney: values.flightJourney.map((journey) => ({
          ...journey,
          from: journey.airportFrom.iata,
          to: journey.airportTo.iata,
        })),
        passengerName: passenger.name,
        type: "INSURANCE",
      })),
    }

    await createInsuranceMutation.mutateAsync({ ...req })
  }

  return (
    <Container className="px-0">
      <FormProvider {...form}>
        <Stack gap={2}>
          <Card>
            <CardHeader title="Tìm gói bảo hiểm theo hành trình" />
            <CardContent>
              <FlightForm isSearch={isSearch} onToggleSearch={onToggleSearch} />
            </CardContent>
          </Card>

          {/* <Card>
            <CardHeader title="AIRPORT" />
            <CardContent>
              <SearchAirport />
            </CardContent>
          </Card> */}

          <Card>
            <CardHeader title="Thông tin khách hàng" />
            <CardContent>
              <PassengerForm />
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Thông tin đại lý" />
            <CardContent>
              <AgencyForm />
            </CardContent>
          </Card>

          <Flex className="justify-center gap-4 sm:gap-6">
            <Button
              color="inherit"
              onClick={() => {
                reset(defaultValues)
                onToggleSearch(true)
              }}
            >
              Đặt lại
            </Button>
            <LoadingButton
              className="px-12"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              Thêm gói bảo hiểm
            </LoadingButton>
          </Flex>
        </Stack>
      </FormProvider>
    </Container>
  )
}

export default SpecialServiceCreate
