import { P } from "models/Permissions"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { profileSelector } from "reducers/profileSlice"
import { privateRoute } from "routes"

const useHomePath = () => {
  const { permissions } = useSelector(profileSelector)

  return useMemo(() => {
    const isViewInsurance = permissions?.some((permission) => permission === P.SPECIAL_SERVICE_FETCH)
    if (isViewInsurance) {
      return privateRoute.specialServices.path
    }
    return privateRoute.airportLoungeBookings.path
  }, [permissions])
}

export default useHomePath
