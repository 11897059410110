import { Grid, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { Controller, useFormContext } from "react-hook-form"
import { formatFlightTrip } from "utils/common"

const FlightForm = () => {
  const { control } = useFormContext<AirportLoungeBookingCreateBody>()

  return (
    <Grid columnSpacing={6} container rowSpacing={4}>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="flightCode"
          render={({ field, fieldState: { error } }) => (
            <TextField fullWidth label="Mã đặt chỗ" {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="flightTrip"
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label="Hành trình"
              {...field}
              error={!!error}
              helperText={error?.message}
              onChange={(event) => {
                const formattedTrip = formatFlightTrip(event.target.value)
                field.onChange(formattedTrip)
              }}
            />
          )}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="flightTime"
          render={({ field, fieldState: { error } }) => <DatePicker format="dd/MM/yyyy" label="Ngày bay" {...field} />}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          control={control}
          name="flightNumber"
          render={({ field, fieldState: { error } }) => (
            <TextField fullWidth label="Số hiệu chuyến bay" {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </Grid>
      <Grid item lg={8} md={6} xs={12}>
        <Controller
          control={control}
          name="flightRemark"
          render={({ field, fieldState: { error } }) => (
            <TextField fullWidth label="Remark" multiline {...field} error={!!error} helperText={error?.message} />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default FlightForm
