import { client } from "./axios"

const fetchAuditLogs = (params?: AuditLogParams): Promise<PaginateResponse<AuditLog>> =>
  client.get(`/audit_logs`, { params })

const auditLogService = {
  fetchAuditLogs,
}

export default auditLogService
