import { Add, Check, Edit, KeySharp, Remove } from "@mui/icons-material"
import { Box, Button, Chip, Stack, Tooltip, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query"
import { Flex } from "components/common"
import { useSearch } from "hooks"
import { useConfirmationDialog } from "hooks/useConfirmationDialog"
import { P } from "models/Permissions"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useToggle } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { queryClient, userService } from "services"

import { UserCreateDialog, UserResetPasswordDialog, UserSearch, UserUpdateDialog } from "."

const UserList = () => {
  const { openConfirm } = useConfirmationDialog()
  const { isTypeSystem, permissions = [] } = useSelector(profileSelector)
  const { dataSearch, onSearchChange } = useSearch()

  const [isOpenCreate, onCreate] = useToggle(false)
  const [isOpenUpdate, onUpdate] = useToggle(false)
  const [isOpenChangePw, onChangePw] = useToggle(false)

  const [user, setUser] = useState<User>()

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => userService.fetchUsers(dataSearch),
    queryKey: ["userService.fetchUsers", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  const enableUserMutation = useMutation({ mutationFn: userService.enableUser })
  const disableUserMutation = useMutation({ mutationFn: userService.disableUser })

  const changeUserStatus = async (user: User) => {
    if (user.enabled) {
      await disableUserMutation.mutateAsync({ id: user.id })
      enqueueSnackbar("Khoá tài khoản thành công")
      queryClient.invalidateQueries({ queryKey: ["userService.fetchUsers"] })
    } else {
      await enableUserMutation.mutateAsync({ id: user.id })
      enqueueSnackbar("Kích hoạt tài khoản thành công")
      queryClient.invalidateQueries({ queryKey: ["userService.fetchUsers"] })
    }
  }

  return (
    <Box>
      <Flex className="justify-between">
        <Typography variant="h4">User</Typography>
        {permissions.includes(P.USER_CREATE) && (
          <Button
            onClick={() => {
              onCreate(true)
            }}
            startIcon={<Add />}
          >
            Tạo mới
          </Button>
        )}
      </Flex>
      <UserSearch marginTop={3} onSearchChange={onSearchChange} />

      <DataGrid
        columns={([] as GridColDef<User>[])
          .concat([
            {
              field: "username",
              flex: 1,
              headerName: "Tài khoản",
              minWidth: 180,
              renderCell: ({ row: item }) => {
                return (
                  <Stack>
                    <Typography className="font-bold" variant="body2">
                      {item.username}
                    </Typography>
                    <Typography variant="body2">
                      {isTypeSystem && item.userType} {item.role}
                    </Typography>
                  </Stack>
                )
              },
              sortable: false,
            },
          ])
          .concat(
            isTypeSystem
              ? [
                  {
                    field: "agency",
                    flex: 1,
                    headerName: "Đại lý",
                    minWidth: 120,
                    renderCell: ({ row: item }) => item.agency.name,
                    sortable: false,
                  },
                ]
              : [],
          )
          .concat([
            {
              field: "name",
              flex: 1,
              headerName: "Thông tin",
              minWidth: 240,
              renderCell: ({ row: item }) => {
                return (
                  <Stack>
                    <Typography variant="body2">
                      {item.lastName} {item.firstName}
                    </Typography>
                    <Typography variant="body2">{item.email}</Typography>
                  </Stack>
                )
              },
              sortable: false,
            },
            {
              field: "status",
              headerName: "Trạng thái",
              renderCell: ({ row: item }) => {
                if (item.enabled) return <Chip color="success" label="Active" />
                if (!item.enabled) return <Chip label="Disabled" />
              },
              sortable: false,
              width: 160,
            },
            {
              align: "right",
              field: "actions",
              headerName: "",
              renderCell: ({ row: item }) => {
                return (
                  <Flex className="flex-nowrap gap-2">
                    {permissions.includes(P.USER_UPDATE) && (
                      <Tooltip title="Cập nhật">
                        <Button
                          onClick={() => {
                            setUser(item)
                            onUpdate(true)
                          }}
                          size="small"
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    )}
                    {permissions.includes(P.USER_DISABLE) && item.enabled && (
                      <Tooltip title="Khoá tài khoản">
                        <Button
                          color="error"
                          onClick={() => openConfirm({ onConfirmAsync: () => changeUserStatus(item) })}
                          size="small"
                        >
                          <Remove />
                        </Button>
                      </Tooltip>
                    )}
                    {permissions.includes(P.USER_ENABLE) && !item.enabled && (
                      <Tooltip title="Kích hoạt tài khoản">
                        <Button
                          color="success"
                          onClick={() => openConfirm({ onConfirmAsync: () => changeUserStatus(item) })}
                          size="small"
                        >
                          <Check />
                        </Button>
                      </Tooltip>
                    )}
                    {permissions.includes(P.USER_RESET_PW) && (
                      <Tooltip title="Đổi mật khẩu">
                        <Button
                          color="warning"
                          onClick={() => {
                            setUser(item)
                            onChangePw(true)
                          }}
                          size="small"
                        >
                          <KeySharp />
                        </Button>
                      </Tooltip>
                    )}
                  </Flex>
                )
              },
              sortable: false,
              width: 128 + 52,
            },
          ])}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />
      <UserUpdateDialog onClose={() => onUpdate(false)} open={isOpenUpdate} user={user} />
      <UserCreateDialog onClose={() => onCreate(false)} open={isOpenCreate} />
      <UserResetPasswordDialog onClose={() => onChangePw(false)} open={isOpenChangePw} user={user} />
    </Box>
  )
}

export default UserList
