import { Add, DeleteOutlined, Edit } from "@mui/icons-material"
import { Box, Button, Stack, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query"
import { Flex } from "components/common"
import { useConfirmationDialog, useSearch } from "hooks"
import { P } from "models/Permissions"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useToggle } from "react-use"
import { profileSelector } from "reducers/profileSlice"
import { airportLoungeFareService, queryClient } from "services"
import { formatCurrency } from "utils/common"

import { AirportLoungeFareDialog, AirportLoungeFareSearch } from "."

const AirportLoungeFareList = () => {
  const { openConfirm } = useConfirmationDialog()
  const { dataSearch, onSearchChange } = useSearch({ sortBy: "airportLounge.id" })
  const { permissions = [] } = useSelector(profileSelector)

  const [isOpen, onToggle] = useToggle(false)
  const [airportLoungeFare, setAirportLoungeFare] = useState<AirportLoungeFare>()

  const { data } = useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => airportLoungeFareService.fetchLoungeFare(dataSearch),
    queryKey: ["airportLoungeFareService.fetchLoungeFare", dataSearch],
  })
  const { items = [], page = 1, size = 10, sortBy, sortDirection, total = 0 } = data ?? {}

  const deleteLoungeMutation = useMutation({
    mutationFn: airportLoungeFareService.deleteLoungeFare,
    onSuccess: () => {
      enqueueSnackbar("Xoá loại vé thành công")
      queryClient.invalidateQueries({ queryKey: ["airportLoungeFareService.fetchLoungeFare"] })
    },
  })

  return (
    <Box>
      <Flex className="justify-between">
        <Typography variant="h4">Loại vé</Typography>
        {permissions.includes(P.AIRPORT_LOUNGE_TICKET_CREATE) && (
          <Button
            onClick={() => {
              setAirportLoungeFare(undefined)
              onToggle(true)
            }}
            startIcon={<Add />}
          >
            Tạo mới
          </Button>
        )}
      </Flex>
      <AirportLoungeFareSearch marginTop={3} onSearchChange={onSearchChange} />

      <DataGrid
        columns={[
          {
            field: "airportLounge.name",
            flex: 1,
            headerName: "Phòng chờ",
            renderCell: ({ row }) => (
              <Stack component="li" style={{ alignItems: "flex-start" }}>
                <Box>{row.airportLounge.name}</Box>
                <Box className="text-sm text-gray-600">
                  {row.airportLounge.airport.name} - {row.airportLounge.isDomestic ? "Nội địa" : "Quốc tế"}
                </Box>
              </Stack>
            ),
            sortable: false,
          },
          { field: "name", flex: 1, headerName: "Loại vé", sortable: false },
          {
            field: "price",
            flex: 1,
            headerName: "Giá tiền",
            renderCell: ({ row }) => formatCurrency(row.price),
            sortable: true,
          },
          {
            align: "right",
            field: "actions",
            headerName: "",
            renderCell: ({ row: item }) => {
              return (
                <Flex className="flex-nowrap gap-2">
                  {permissions.includes(P.AIRPORT_LOUNGE_TICKET_UPDATE) && (
                    <Button
                      onClick={() => {
                        setAirportLoungeFare(item)
                        onToggle(true)
                      }}
                      size="small"
                    >
                      <Edit />
                    </Button>
                  )}
                  {permissions.includes(P.AIRPORT_LOUNGE_TICKET_DELETE) && (
                    <Button
                      color="error"
                      onClick={() => {
                        openConfirm({
                          onConfirmAsync: () => deleteLoungeMutation.mutateAsync({ id: item.id }),
                        })
                      }}
                      size="small"
                    >
                      <DeleteOutlined />
                    </Button>
                  )}
                </Flex>
              )
            },
            sortable: false,
            width: 128,
          },
        ]}
        onPaginationModelChange={(model) => {
          const { page, pageSize } = model
          onSearchChange({ page: page + 1, size: pageSize })
        }}
        onSortModelChange={(model) => {
          const { field, sort } = model[0] ?? {}
          onSearchChange({ sortBy: field ?? null, sortDirection: sort ?? null })
        }}
        paginationModel={{ page: page - 1, pageSize: size }}
        rowCount={total}
        rows={items}
        sortModel={sortBy ? [{ field: sortBy, sort: sortDirection }] : []}
      />
      <AirportLoungeFareDialog airportLoungeFare={airportLoungeFare} onClose={() => onToggle(false)} open={isOpen} />
    </Box>
  )
}

export default AirportLoungeFareList
