import { DateTime } from "luxon"

export const convertBookingToFormValues = (
  booking?: AirportLoungeBooking,
  newBookingFares?: { airportLoungeFareId: number; count: number }[],
): AirportLoungeBookingCreateBody => {
  if (!booking) {
    return {
      agencyId: "",
      airportId: "",
      airportLoungeId: "",
      bookingFares: [],
      contactEmail: "",
      contactName: "",
      contactPhone: "",
      flightCode: "",
      flightNumber: "",
      flightRemark: "",
      flightTime: null,
      flightTrip: "",
      servingFrom: null,
    }
  }

  return {
    agencyId: booking.agency.id,
    airportId: booking.airport.id,
    airportLoungeId: booking.airportLounge.id,
    bookingFares:
      newBookingFares ??
      booking.bookingFares.map((booking) => ({
        airportLoungeFareId: booking.airportLoungeFare.id,
        count: booking.count,
      })),
    contactEmail: booking.contactEmail ?? "",
    contactName: booking.contactName ?? "",
    contactPhone: booking.contactPhone ?? "",
    flightCode: booking.flightCode ?? "",
    flightNumber: booking.flightNumber ?? "",
    flightRemark: booking.flightRemark ?? "",
    flightTime: booking.flightTime ? (DateTime.fromISO(booking.flightTime) as any) : null,
    flightTrip: booking.flightTrip ?? "",
    servingFrom: booking.servingFrom ? (DateTime.fromISO(booking.servingFrom) as any) : null,
  }
}

export const getChipLabelByStatus = (status: string) => {
  switch (status) {
    case "PENDING_PAYMENT":
      return "Chưa thanh toán"
    case "PAID":
      return "Đã thanh toán"
    case "REFUND":
      return "Đã hoàn"
    case "CANCEL":
      return "Đã huỷ"
    case "PENDING_SUBMISSION":
      return "Chờ xác nhận"
    default:
      return status
  }
}
