import { Menu as MenuIcon } from "@mui/icons-material"
import { AppBar, Box, Divider, Drawer, IconButton, Toolbar } from "@mui/material"
import { AppBreadcrumb, AppProfile } from "components"
import { Center } from "components/common"
import { AppMenu } from "containers"
import { useHomePath, useResponsive } from "hooks"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

const AppHeader = () => {
  const location = useLocation()
  const { isDesktop, isMobile } = useResponsive()
  const homePath = useHomePath()

  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    setOpenDrawer(false)
  }, [location])

  return (
    <>
      <Drawer
        anchor="left"
        onClose={() => setOpenDrawer(false)}
        open={isDesktop ? true : openDrawer}
        PaperProps={{ style: { padding: "8px 16px", width: "320px" } }}
        variant={isDesktop ? "persistent" : "temporary"}
      >
        <Center className="mb-2 h-[48px] gap-3">
          <Link to={homePath}>
            <img className="h-[40px]" src={require("assets/icons/logo.png")} />
          </Link>
        </Center>
        <Divider />
        <AppMenu />
      </Drawer>

      <AppBar color="inherit" elevation={1} position="sticky">
        <Toolbar>
          {!isDesktop && (
            <IconButton onClick={() => setOpenDrawer(true)}>
              <MenuIcon />
            </IconButton>
          )}
          <Box className="flex-1">{!isMobile && <AppBreadcrumb />}</Box>

          <AppProfile />
        </Toolbar>
      </AppBar>
    </>
  )
}

export default AppHeader
